import Input from "../components/inputs/Input";
import { AiOutlineLoading } from "react-icons/ai";
import { MdBusinessCenter, MdOutlinePayments, MdPix } from "react-icons/md";
import {
  Alert,
  Badge,
  Button,
  Card,
  Label,
  Spinner,
  Tabs,
  TextInput,
} from "flowbite-react";
import { useForm } from "react-hook-form";
import { COMPANIES, PAYMENTS } from "../config/apiConfig";
import axios from "../config/axiosInstance";
import { useEffect, useState } from "react";
import { useApplication } from "../contexts/ApplicationContext";
import { useQuery } from "react-query";
import PhoneInput from "../components/inputs/PhoneInput";
import { HiInformationCircle } from "react-icons/hi";
import CPFInput from "../components/inputs/CPFInput";
import { FaRegCopy, FaRegCreditCard } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const PaymentPage = () => {
  const navigate = useNavigate();
  const { user } = useApplication();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorSubmit, setErrorSubmit] = useState(null);
  const [alert, setAlert] = useState({ show: false, message: "", type: "" });
  const [activePayment, setActivePayment] = useState("CREDIT_CARD");
  const [qrCode, setQrCode] = useState("");
  const [codePix, setCodePix] = useState("");
  const [copiedPix, setCopiedPix] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm();

  const {
    isLoading,
    error,
    data: company,
  } = useQuery(["company", user], async () => {
    if (user?.id) {
      const { data: item } = await axios.get(
        `${COMPANIES.GET_COMPANY}/${user.companyId}`
      );

      return item.data;
    }
  });

  useEffect(() => {
    if (company) {
      Object.keys(company).forEach((key) => {
        setValue(key, company[key]);
      });
    }
  }, [company]);

  const onSubmitCreditCard = async (data) => {
    try {
      setIsSubmitting(true);

      data.creditCardHolderInfo.cpfCnpj =
        data.creditCardHolderInfo.cpfCnpj.replace(/\D/g, "");
      data.creditCardHolderInfo.phone = data.creditCardHolderInfo.phone.replace(
        /\D/g,
        ""
      );

      await axios.post(`${PAYMENTS.NEW_SIGNATURE}`, data);

      navigate("/payment-confirm");
    } catch (error) {
      setErrorSubmit(error);
      setAlert({
        show: true,
        message: "Erro ao tentar criar sua assinatura!",
        type: "failure",
      });
    } finally {
      setIsSubmitting(false);
      setTimeout(() => {
        setAlert({
          show: false,
          message: "",
          type: "",
        });
      }, 2000);
    }
  };

  const onSubmitPIX = async (data) => {
    try {
      setIsSubmitting(true);

      data.pix.cpfCnpj = data.pix.cpfCnpj.replace(/\D/g, "");
      data.pix.phone = data.pix.phone.replace(/\D/g, "");

      const asaas = await axios.post(`${PAYMENTS.NEW_SIGNATURE_PIX}`, data);

      setQrCode(asaas.data.asaasData.encodedImage);
      setCodePix(asaas.data.asaasData.payload);
    } catch (error) {
      setErrorSubmit(error);
      setAlert({
        show: true,
        message: "Erro ao tentar criar sua assinatura via PIX!",
        type: "failure",
      });
    } finally {
      setIsSubmitting(false);
      setTimeout(() => {
        setAlert({
          show: false,
          message: "",
          type: "",
        });
      }, 2000);
    }
  };

  const handleCopy = () => {
    navigator.clipboard
      .writeText(codePix)
      .then(() => {
        setCopiedPix(true);
        setTimeout(() => setCopiedPix(false), 2000);
      })
      .catch((error) => {
        console.error("Erro ao copiar o código PIX: ", error);
      });
  };

  return (
    <div className="bg-white px-4 py-4 rounded-md shadow-md">
      <div>
        <div className="font-semibold">Formas de pagamento</div>
        <div className="text-sm">Selecione uma opção abaixo:</div>
        <ul className="flex gap-4 mt-4">
          <li
            className={`border border-gray-300 rounded p-2 flex flex-col justify-center items-center w-40 h-16 gap-1 cursor-pointer ${
              activePayment === "CREDIT_CARD" ? "bg-primary text-white" : ""
            }`}
            onClick={() => setActivePayment("CREDIT_CARD")}
          >
            <FaRegCreditCard />
            <div className="text-sm">Cartão de crédito</div>
          </li>
          <li
            className={`border border-gray-300 rounded p-2 flex flex-col justify-center items-center w-40 h-16 gap-1 cursor-pointer ${
              activePayment === "PIX" ? "bg-primary text-white" : ""
            }`}
            onClick={() => setActivePayment("PIX")}
          >
            <MdPix />
            <div className="text-sm">PIX</div>
          </li>
        </ul>
      </div>
      {activePayment === "CREDIT_CARD" && (
        <div className="flex gap-12 mt-6">
          <form className="flex-1" onSubmit={handleSubmit(onSubmitCreditCard)}>
            <h2 className="mb-5 font-semibold text-center">
              Assinatura PRO por cartão de crédito
            </h2>
            <div className="mb-6">
              <Input
                type="number"
                label="Número do cartão *"
                name="creditCard.number"
                required={true}
                register={register}
                error={errors?.creditCard?.number && "Este campo é obrigatório"}
              />
            </div>
            <div className="flex gap-2">
              <div className="mb-6 flex-1">
                <Input
                  type="text"
                  label="Mês/Exp. *"
                  name="creditCard.expiryMonth"
                  required={true}
                  register={register}
                  error={
                    errors?.creditCard?.expiryMonth &&
                    "Este campo é obrigatório"
                  }
                  maxLength="2"
                />
              </div>
              <div className="mb-6 flex-1">
                <Input
                  type="text"
                  label="Ano/Exp. *"
                  name="creditCard.expiryYear"
                  required={true}
                  register={register}
                  error={
                    errors?.creditCard?.expiryYear && "Este campo é obrigatório"
                  }
                  maxLength="4"
                />
              </div>
              <div className="mb-6 flex-1">
                <Input
                  type="text"
                  label="CCV *"
                  name="creditCard.ccv"
                  required={true}
                  register={register}
                  error={errors?.creditCard?.ccv && "Este campo é obrigatório"}
                  maxLength="3"
                />
              </div>
            </div>
            <div className="mb-6">
              <Input
                type="text"
                label="Nome impresso no cartão *"
                name="creditCard.holderName"
                required={true}
                register={register}
                error={
                  errors?.creditCard?.holderName && "Este campo é obrigatório"
                }
              />
            </div>

            <div className="mb-6 border-b pb-3 border-gray-200 font-semibold">
              Informações do titular do cartão
            </div>

            <div className="mb-6">
              <Input
                type="text"
                label="Nome completo *"
                name="creditCardHolderInfo.name"
                required={true}
                register={register}
                error={
                  errors?.creditCardHolderInfo?.name &&
                  "Este campo é obrigatório"
                }
              />
            </div>

            <div className="mb-6">
              <Input
                type="email"
                label="E-mail *"
                name="creditCardHolderInfo.email"
                required={true}
                register={register}
                error={
                  errors?.creditCardHolderInfo?.email &&
                  "Este campo é obrigatório"
                }
              />
            </div>

            <div className="mb-6">
              <PhoneInput
                label="Celular com DDD *"
                name="creditCardHolderInfo.phone"
                required={true}
                register={register}
                error={
                  errors?.creditCardHolderInfo?.phone &&
                  "Este campo é obrigatório"
                }
              />
            </div>

            <div className="mb-6">
              <CPFInput
                label="CPF *"
                name="creditCardHolderInfo.cpfCnpj"
                required={true}
                register={register}
                error={
                  errors?.creditCardHolderInfo?.cpfCnpj &&
                  "Este campo é obrigatório"
                }
              />
            </div>

            <div className="flex gap-2">
              <div className="mb-6 flex-1">
                <Input
                  type="text"
                  label="CEP *"
                  name="creditCardHolderInfo.postalCode"
                  required={true}
                  register={register}
                  error={
                    errors?.creditCardHolderInfo?.postalCode &&
                    "Este campo é obrigatório"
                  }
                />
              </div>

              <div className="mb-6">
                <Input
                  type="number"
                  label="Número *"
                  name="creditCardHolderInfo.addressNumber"
                  required={true}
                  register={register}
                  error={
                    errors?.creditCardHolderInfo?.addressNumber &&
                    "Este campo é obrigatório"
                  }
                />
              </div>
            </div>

            <Button
              type="submit"
              className="w-full primary"
              isProcessing={isSubmitting}
              processingSpinner={
                <AiOutlineLoading className="h-6 w-6 animate-spin" />
              }
              disabled={isSubmitting}
            >
              Confirmar assinatura
            </Button>
            {alert.show && (
              <Alert className="mt-4" color={`${alert.type}`}>
                <span className="font-medium">{alert.message}</span>
              </Alert>
            )}

            {error && (
              <Alert color="failure" icon={HiInformationCircle}>
                Erro ao tentar criar assinatura
              </Alert>
            )}
          </form>
          <div className="hidden md:block w-72 bg-gray-100 rounded p-3">
            <div className="font-semibold text-center border-b border-gray-200 pb-6">
              Resumo do pedido
            </div>
            <div className="flex justify-between mt-6 text-sm border-b border-gray-200 pb-6">
              <div>Plano PRO</div>
              <div className="font-semibold">R$ 29,90/mês</div>
            </div>
            <div className="flex justify-between mt-6 text-sm border-b border-gray-200 pb-6">
              <div className="text-sm">Descontos</div>
              <div className="font-semibold">R$ 00,00</div>
            </div>
            <div className="flex justify-between mt-6 text-sm">
              <div className="text-lg">Total</div>
              <div className="font-semibold text-lg text-green-700">
                R$ 29,90
              </div>
            </div>
            <div className="text-center mt-5 text-sm">
              A mensalidade será cobrada de forma automática e recorrente.
            </div>
            <img
              className="mt-10 mx-auto"
              style={{ width: "200px" }}
              src="/assets/payment_card.png"
            />
            <div className="flex items-center gap-2 mt-5">
              <img className="w-14" src="/assets/security.png" />
              <div className="text-xs">
                <div className="font-semibold">Pagamento Seguro SSL</div>
                <div>
                  Sua criptografia é protegida por criptografia SSL de 256 bits
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {activePayment === "PIX" && (
        <div className="flex gap-12 mt-6">
          {qrCode === "" && codePix === "" ? (
            <form className="flex-1" onSubmit={handleSubmit(onSubmitPIX)}>
              <h2 className="mb-5 font-semibold text-center">
                Assinatura PRO por PIX
              </h2>

              <div className="mb-6 border-b pb-3 border-gray-200 font-semibold">
                Informações do titular do pagamento
              </div>

              <div className="mb-6">
                <Input
                  type="text"
                  label="Nome completo *"
                  name="pix.name"
                  required={true}
                  register={register}
                  error={errors?.pix?.name && "Este campo é obrigatório"}
                />
              </div>

              <div className="mb-6">
                <Input
                  type="email"
                  label="E-mail *"
                  name="pix.email"
                  required={true}
                  register={register}
                  error={errors?.pix?.email && "Este campo é obrigatório"}
                />
              </div>

              <div className="mb-6">
                <PhoneInput
                  label="Celular com DDD *"
                  name="pix.phone"
                  required={true}
                  register={register}
                  error={errors?.pix?.phone && "Este campo é obrigatório"}
                />
              </div>

              <div className="mb-6">
                <CPFInput
                  label="CPF *"
                  name="pix.cpfCnpj"
                  required={true}
                  register={register}
                  error={errors?.pix?.cpfCnpj && "Este campo é obrigatório"}
                />
              </div>

              <div className="flex gap-2">
                <div className="mb-6 flex-1">
                  <Input
                    type="text"
                    label="CEP *"
                    name="pix.postalCode"
                    required={true}
                    register={register}
                    error={
                      errors?.pix?.postalCode && "Este campo é obrigatório"
                    }
                  />
                </div>

                <div className="mb-6">
                  <Input
                    type="number"
                    label="Número *"
                    name="pix.addressNumber"
                    required={true}
                    register={register}
                    error={
                      errors?.pix?.addressNumber && "Este campo é obrigatório"
                    }
                  />
                </div>
              </div>

              <Button
                type="submit"
                className="w-full primary"
                isProcessing={isSubmitting}
                processingSpinner={
                  <AiOutlineLoading className="h-6 w-6 animate-spin" />
                }
                disabled={isSubmitting}
              >
                Realizar pagamento PIX
              </Button>
              {alert.show && (
                <Alert className="mt-4" color={`${alert.type}`}>
                  <span className="font-medium">{alert.message}</span>
                </Alert>
              )}

              {error && (
                <Alert color="failure" icon={HiInformationCircle}>
                  Erro ao tentar criar pagamento via PIX
                </Alert>
              )}
            </form>
          ) : (
            <div className="flex flex-col items-center flex-1 justify-center">
              <div className="flex items-center justify-center flex-col bg-gray-100 rounded px-2 py-4 gap-2">
                <img src={`data:image/png;base64,${qrCode}`} className="w-40" />
                <div className="w-80 overflow-x-hidden border border-gray-300 rounded p-2 text-sm">
                  {codePix}
                </div>
                <div
                  className="flex items-center gap-1 mt-3 text-sm cursor-pointer"
                  onClick={handleCopy}
                >
                  <FaRegCopy />
                  <span>Copiar código PIX</span>
                  {copiedPix && (
                    <span className="ml-2 text-green-500">Copiado!</span>
                  )}
                </div>
              </div>
              <div className="flex items-center gap-2 mt-4">
                <div className="text-sm">
                  Após realizar o pagamento, seu plano será atualizado
                  automaticamente em até <b>10 minutos</b>.
                </div>
              </div>
            </div>
          )}

          <div className="hidden md:block w-72 bg-gray-100 rounded p-3">
            <div className="font-semibold text-center border-b border-gray-200 pb-6">
              Resumo do pedido
            </div>
            <div className="flex justify-between mt-6 text-sm border-b border-gray-200 pb-6">
              <div>Plano PRO</div>
              <div className="font-semibold">R$ 29,90/mês</div>
            </div>
            <div className="flex justify-between mt-6 text-sm border-b border-gray-200 pb-6">
              <div className="text-sm">Descontos</div>
              <div className="font-semibold">R$ 00,00</div>
            </div>
            <div className="flex justify-between mt-6 text-sm">
              <div className="text-lg">Total</div>
              <div className="font-semibold text-lg text-green-700">
                R$ 29,90
              </div>
            </div>
            <img
              className="mt-10 mx-auto"
              style={{ width: "200px" }}
              src="/assets/payment_card.png"
            />
            <div className="flex items-center gap-2 mt-5">
              <img className="w-14" src="/assets/security.png" />
              <div className="text-xs">
                <div className="font-semibold">Pagamento Seguro SSL</div>
                <div>
                  Sua criptografia é protegida por criptografia SSL de 256 bits
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PaymentPage;
