import React, { useEffect, useState, useRef } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import axios from "../config/axiosInstance";
import { APPOINTMENTS, COMPANIES } from "../config/apiConfig";
import "react-big-calendar/lib/css/react-big-calendar.css";
import {
  Button,
  Datepicker,
  Card,
  Spinner,
  Avatar,
  Alert,
} from "flowbite-react";
import Drawer from "./Drawer";
import AppointmentForm from "./forms/AppointmentForm";
import moment from "moment";
import "moment-timezone";
import "moment/locale/pt-br";
import CalendarEvent from "./CalendarEvent";
import CalendarCard from "./CalendarCard";
import { useQuery, useQueryClient } from "react-query";
import CalendarProfessionalLabels from "./CalendarProfessionalLabels";
import { calculateCurrentDates } from "../utils/dates";
import { IoIosArrowBack, IoIosArrowForward, IoMdAdd } from "react-icons/io";
import { useApplication } from "../contexts/ApplicationContext";
import FabButton from "./FabButton";
import Loading from "./Loading";
import { HiInformationCircle } from "react-icons/hi";
import { Link } from "react-router-dom";

moment.tz.setDefault("America/Sao_Paulo");
moment.locale("pt-br");
const localizer = momentLocalizer(moment);

const eventStyleGetter = (event, start, end, isSelected) => {
  const style = {
    backgroundColor: event.color,
    borderRadius: "8px",
    color: "black",
    border: "solid 1px white",
    margin: "2px",
    opacity: event.statusId === -1 ? "0.4" : "1",
  };
  return {
    style,
    title: event.title,
    description: event.description,
  };
};

const Calendars = () => {
  const { user } = useApplication();
  const [view, setView] = useState("day");
  const containerCardEventsRef = useRef(null);
  const [selectedDate, setSelectedDate] = useState(moment());
  const [selectedProfessionalId, setSelectedProfessionalId] = useState(null);
  const [selectedEndHour, setSelectedEndHour] = useState(5);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isTypeBlock, setIsTypeBlock] = useState(false);
  const queryClient = useQueryClient();
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [typeDrawer, setTypeDrawer] = useState("CREATE");
  const [filteredAppointments, setFilteredAppointments] = useState([]);
  const [selectedProfessionals, setSelectedProfessionals] = useState([]);
  const [filterStartDate, setFilterStartDate] = useState(moment(new Date()));
  const [filterEndDate, setFilterEndDate] = useState(moment(new Date()));
  const [selectedHour, setSelectedHour] = useState("");
  const [selectedWorkingHour, setSelectedWorkingHour] = useState(null);

  const {
    isLoading,
    error,
    data: appointments = [],
  } = useQuery(
    ["appointments", filterStartDate, filterEndDate, selectedDate],
    async () => {
      const { data: items } = await axios.get(
        `${APPOINTMENTS.GET_APPOINTMENTS}?startDate=${filterStartDate}&endDate=${filterEndDate}`
      );
      return items.data;
    }
  );

  const {
    isLoadingWorkingHours,
    errorWorkingHours,
    data: WorkingHours,
  } = useQuery(["WorkingHours", user], async () => {
    if (user?.id) {
      const { data: item } = await axios.get(
        `${COMPANIES.GET_COMPANY}/working-hours/${user.companyId}`
      );

      return item.data;
    }
  });

  const handleViewChange = (eventType) => {
    setView(eventType);
  };

  useEffect(() => {
    const filteredArray = appointments
      ?.map((appointment) => {
        if (selectedProfessionals.length === 0) return appointment;

        if (selectedProfessionals.includes(appointment.Professional.id))
          return appointment;

        return null;
      })
      ?.filter((appointment) => appointment !== null);

    setFilteredAppointments(filteredArray);
  }, [appointments, selectedProfessionals]);

  const createArrayAppointments = () => {
    if (filteredAppointments?.length > 0) {
      return filteredAppointments?.map((appointment) => {
        const startHour = moment(appointment.startHour, "HH:mm:ss");
        const endHour = moment(appointment.endHour, "HH:mm:ss");
        const diffMin = endHour.diff(startHour, "minutes");
        return {
          id: appointment.id,
          start: new Date(`${appointment.date} ${appointment.startHour}`),
          end: new Date(`${appointment.date} ${appointment.endHour}`),
          title: appointment.Customer?.name,
          color: !appointment?.isBlock
            ? appointment.Professional.colorSchedule
            : "gray",
          description: appointment.Status?.name,
          statusId: appointment.statusId,
          professionalId: appointment.professionalId,
          professionalName: appointment.Professional?.name,
          serviceId: appointment.serviceId,
          service: appointment?.Services?.map((service) => service.name).join(
            " + "
          ),
          customerId: appointment.customerId,
          startHour: moment(appointment.startHour, "HH:mm:ss").format("HH:mm"),
          endHour: diffMin,
          endHourTime: moment(appointment.endHour, "HH:mm:ss").format("HH:mm"),
          date: appointment.date,
          observation: appointment.observation,
          isVip: appointment.Customer?.isVip,
          phoneNumber: appointment.Customer?.phoneNumber,
          commandId: appointment.commandId,
          command: appointment?.Command,
          isBlock: appointment?.isBlock,
          resourceId: appointment.professionalId,
          Services: appointment.Services,
          isOnline: appointment.isOnline,
          Customer: appointment?.Customer,
          cancellationId: appointment.cancellationId,
        };
      });
    }

    return [];
  };

  const createResources = () => {
    const uniqueAppointments = [];
    const professionalIds = new Set();

    filteredAppointments.forEach((appointment) => {
      if (!professionalIds.has(appointment.professionalId)) {
        uniqueAppointments.push({
          resourceId: appointment.professionalId,
          resourceTitle: (
            <div className="flex flex-col gap-1 items-center py-2">
              <Avatar
                rounded
                size="xs"
                img={appointment.Professional?.imageUrl}
                className="outline outline-2 rounded-full"
                style={{
                  outlineColor:
                    appointment.Professional?.colorSchedule || "white",
                }}
              />
              <span className="text-2xs md:text-md">
                {appointment.Professional.name}
              </span>
            </div>
          ),
        });
        professionalIds.add(appointment.professionalId);
      }
    });

    return uniqueAppointments.length > 0 ? uniqueAppointments : null;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSelectedAppointment((prevAppointment) => ({
      ...prevAppointment,
      [name]: value,
    }));
  };

  const handleBlockCalendar = () => {
    setTypeDrawer("CREATE");
    setIsDrawerOpen(true);
    setIsTypeBlock(true);
  };

  const handleCreateAppointment = () => {
    setTypeDrawer("CREATE");
    setIsDrawerOpen(true);
    setIsTypeBlock(false);
  };

  const setDateFilters = (date) => {
    let startDate = date;
    let endDate = date;

    if (view === "month") {
      startDate = moment(date).startOf("month");
      endDate = moment(date).endOf("month");
    } else if (view === "week") {
      startDate = moment(date).startOf("week");
      endDate = moment(date).endOf("week");
    } else if (["day", "detailed_day"].includes(view)) {
      startDate = moment(date).startOf("day");
      endDate = moment(date).endOf("day");
    }

    setSelectedDate(date);
    setFilterStartDate(startDate);
    setFilterEndDate(endDate);
  };
  const handleCardClick = (date) => {
    setDateFilters(date);
  };

  const CustomToolbar = (toolbar) => {
    const formattedDate = moment(selectedDate).format("dddd, D [de] MMMM");

    return (
      <>
        <div className="rbc-toolbar">
          <span className="rbc-btn-group">
            <span className="rbc-toolbar-label">{formattedDate}</span>
          </span>
        </div>
      </>
    );
  };

  useEffect(() => {
    if (view !== "detailed_day") {
      let itemToScrollTo = +moment(selectedDate).format(
        view === "month" ? "MM" : "DD"
      );
      const contentElement = containerCardEventsRef.current;
      const itemElement = contentElement.children[itemToScrollTo];

      if (itemElement) {
        setTimeout(() => {
          const scrollLeft =
            itemElement.offsetLeft - contentElement.offsetWidth / 2;
          contentElement.scrollTo({
            left: scrollLeft,
            behavior: "smooth",
          });
        }, 50);
      }
    }
  }, [filteredAppointments, view, selectedDate]);

  useEffect(() => {
    setDateFilters(selectedDate);
  }, [view]);

  useEffect(() => {
    setSelectedWorkingHour(
      WorkingHours?.find((wh) => wh.weekday === moment(selectedDate).day())
    );
  }, [selectedDate, WorkingHours]);

  const onClickEvent = (e) => {
    setTypeDrawer("EDIT");
    setSelectedAppointment(e);
    setIsDrawerOpen(true);
  };

  const handleSelectSlot = ({ start, end, action, resourceId }) => {
    if (view !== "month") {
      const startHour = moment(start);
      const endHour = moment(end);
      const minutesDiff = endHour.diff(startHour, "minutes");

      handleCreateAppointment();
      setSelectedHour(moment(start).format("HH:mm"));
      setSelectedEndHour(minutesDiff);
      setSelectedProfessionalId(resourceId);
    } else {
      setView("day");
      setSelectedDate(moment(start).format("YYYY-MM-DD"));
    }
  };

  const handlePrevNextButtonDate = (type) => {
    let newDate;
    const typeDate = view !== "week" ? "month" : "week";

    if (type === "PREV") {
      newDate = moment(selectedDate).startOf(typeDate).subtract(1, "day");
    } else if (type === "NEXT") {
      newDate = moment(selectedDate).endOf(typeDate).add(1, "day");
    }

    setSelectedDate(newDate);
    setDateFilters(newDate);
  };

  return (
    <>
      {user?.Company?.planId === "PRO" &&
        moment(user?.Company?.planExpirationDate, "YYYY-MM-DD").isBefore(
          moment().subtract(4, "days")
        ) && (
          <Alert
            withBorderAccent
            color="failure"
            className="mb-5"
            icon={HiInformationCircle}
          >
            Não conseguimos identificar o pagamento do seu plano PRO. Caso o
            pagamento não seja confirmado em breve, seu plano será revertido
            para a versão FREE.{" "}
            <Link to="/payment" className="underline">
              Pague agora mesmo
            </Link>
          </Alert>
        )}
      <div className="flex flex-col md:flex-row justify-end gap-5">
        <div className="flex items-center gap-2 justify-end">
          <Datepicker
            weekStart={1}
            language="pt-BR"
            labelTodayButton="Hoje"
            labelClearButton="Limpar"
            value={moment(new Date(selectedDate)).format("DD MMMM yyyy")}
            onSelectedDateChanged={(date) => setDateFilters(moment(date))}
          />
          <ul className="bg-gray-200 rounded-full flex text-2xs md:text-xs font-semibold">
            <li
              className={`${
                view === "day" ? "bg-primary text-white shadow" : ""
              } rounded-full py-3 px-3 cursor-pointer`}
              onClick={() => handleViewChange("day")}
            >
              Dia
            </li>
            <li
              className={`${
                view === "week" ? "bg-primary text-white shadow" : ""
              } rounded-full py-3 px-3 cursor-pointer`}
              onClick={() => handleViewChange("week")}
            >
              Semana
            </li>
            <li
              className={`${
                view === "month" ? "bg-primary text-white shadow" : ""
              } rounded-full py-3 px-3 cursor-pointer`}
              onClick={() => handleViewChange("month")}
            >
              Mês
            </li>
          </ul>
        </div>
        <Button
          className="bg-gray-500 hidden md:flex ml-auto mb-0 relative rounded-md shadow-md h-auto w-fit justify-center items-center"
          onClick={handleBlockCalendar}
        >
          <span className="block">Bloquear horário </span>
        </Button>
        <Button
          className="primary ml-auto md:ml-0 mb-5 md:mb-0 fixed md:relative bottom-4 md:bottom-0 right-6 md:right-0 z-10 md:z-0 rounded-full md:rounded-md shadow-lg md:shadow-md h-14 md:h-auto w-14 md:w-auto hidden md:block justify-center items-center"
          onClick={handleCreateAppointment}
        >
          <IoMdAdd className="block md:hidden text-2xl" />
          <span className="hidden md:block">Novo agendamento</span>
        </Button>
      </div>
      <div className="overflow-x-hidden">
        {view !== "detailed_day" && (
          <div
            className="flex gap-2 mt-5 overflow-x-scroll py-5"
            ref={containerCardEventsRef}
          >
            <Button
              className="flex items-center primary"
              onClick={() => handlePrevNextButtonDate("PREV")}
            >
              <IoIosArrowBack />
            </Button>
            {calculateCurrentDates(view, selectedDate).map((date, index) => (
              <CalendarCard
                key={index}
                date={date}
                onClick={() => handleCardClick(date)}
                selectedDate={selectedDate}
                view={view}
              />
            ))}
            <Button
              className="flex items-center primary"
              onClick={() => handlePrevNextButtonDate("NEXT")}
            >
              <IoIosArrowForward />
            </Button>
          </div>
        )}
      </div>
      {!error && selectedWorkingHour ? (
        <>
          <div>
            <section className="antialiased text-gray-600 container mx-auto pt-2">
              <CalendarProfessionalLabels
                appointments={appointments}
                selectedProfessionals={selectedProfessionals}
                setSelectedProfessionals={setSelectedProfessionals}
              />
              <div className="flex">
                <div className="w-full">
                  <div className="relative pb-16">
                    <div>
                      <Calendar
                        components={{
                          toolbar: CustomToolbar,
                          event: CalendarEvent,
                        }}
                        localizer={localizer}
                        events={createArrayAppointments()}
                        startAccessor="start"
                        endAccessor="end"
                        min={moment().set({
                          hour:
                            view === "day"
                              ? selectedWorkingHour?.startHour.split(":")[0]
                              : 7,
                          minute:
                            view === "day"
                              ? selectedWorkingHour?.startHour.split(":")[1]
                              : 0,
                        })}
                        max={moment().set({
                          hour:
                            view === "day"
                              ? selectedWorkingHour?.endHour.split(":")[0]
                              : 22,
                          minute:
                            view === "day"
                              ? selectedWorkingHour?.endHour.split(":")[1]
                              : 0,
                        })}
                        step={15}
                        formats={{
                          timeGutterFormat: (date, culture, localizer) =>
                            localizer.format(date, "HH:mm", culture),
                        }}
                        onSelectEvent={onClickEvent}
                        messages={{
                          next: "Próximo",
                          previous: "Anterior",
                          today: "Hoje",
                          month: "Mês",
                          week: "Semana",
                          day: "Dia",
                          agenda: "Agenda",
                          showMore: (count, remainingEvents, events) => (
                            <span
                              onClick={() => {
                                setView("day");
                                setSelectedDate(
                                  moment(events[0].start).format("YYYY-MM-DD")
                                );
                              }}
                            >
                              +{count}
                            </span>
                          ),
                        }}
                        eventPropGetter={eventStyleGetter}
                        views={{ month: true, week: true, day: true }}
                        onView={(view) =>
                          console.log("Visualização alterada para:", view)
                        }
                        onNavigate={(date) =>
                          console.log("Data de navegação alterada para:", date)
                        }
                        view={view}
                        date={selectedDate}
                        onSelectSlot={handleSelectSlot}
                        selectable={"ignoreEvents"}
                        style={{
                          height: view === "month" ? "auto" : "2800px",
                        }}
                        resources={createResources()}
                        resourceIdAccessor="resourceId"
                        resourceTitleAccessor="resourceTitle"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </>
      ) : (
        <Loading />
      )}

      {error && (
        <Card className="text-center mt-4">
          Ocorreu um erro ao buscar agendamentos
        </Card>
      )}

      <Drawer
        title={`${
          isTypeBlock
            ? "Novo bloqueio de horário"
            : typeDrawer === "CREATE"
            ? "Cadastrar novo agendamento"
            : "Editar agendamento"
        }`}
        onClose={setIsDrawerOpen}
        isOpen={isDrawerOpen}
        className="w-full md:w-2/5 pb-36"
      >
        <AppointmentForm
          appointment={selectedAppointment}
          isLoadingAppointment={isLoading}
          selectedDate={moment(selectedDate).format("YYYY-MM-DD")}
          selectedProfessionalId={selectedProfessionalId}
          handleInputChange={handleInputChange}
          fetchAppointments={() =>
            queryClient.invalidateQueries(["appointments"])
          }
          isDrawerOpen={isDrawerOpen}
          onClose={setIsDrawerOpen}
          type={typeDrawer}
          selectedHour={selectedHour}
          selectedEndHour={selectedEndHour}
          isTypeBlock={isTypeBlock}
        />
      </Drawer>

      <FabButton>
        <a
          onClick={handleCreateAppointment}
          className="fab__link cursor-pointer"
        >
          Criar agendamento
        </a>
        <a
          onClick={handleBlockCalendar}
          className="fab__link cursor-pointer"
          style={{ background: "gray" }}
        >
          Bloquear horário
        </a>
      </FabButton>
    </>
  );
};

export default Calendars;
