import Input from "../components/inputs/Input";
import { AiOutlineLoading } from "react-icons/ai";
import { MdBusinessCenter, MdOutlinePayments } from "react-icons/md";
import {
  Alert,
  Badge,
  Button,
  Card,
  Label,
  Tabs,
  TextInput,
} from "flowbite-react";
import { useForm } from "react-hook-form";
import { AVATAR, COMPANIES, PAYMENTS } from "../config/apiConfig";
import axios from "../config/axiosInstance";
import { useEffect, useState } from "react";
import { useApplication } from "../contexts/ApplicationContext";
import { useQuery } from "react-query";
import PhoneInput from "../components/inputs/PhoneInput";
import { HiInformationCircle } from "react-icons/hi";
import moment from "moment";
import ImageInput from "../components/inputs/ImageInput";

const CompanyPage = () => {
  const { user } = useApplication();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorSubmit, setErrorSubmit] = useState(null);
  const [alert, setAlert] = useState({ show: false, message: "", type: "" });
  const [avatarImg, setAvatarImg] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);

  const weekdays = [];

  for (let i = 0; i < 7; i++) {
    const day = moment()
      .weekday(i)
      .format("dddd")
      .replace(/^\w/, (weekday) => weekday.toUpperCase());
    weekdays.push(day);
  }

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm();

  const {
    isLoading,
    error,
    data: company,
  } = useQuery(["company", user], async () => {
    if (user?.id) {
      const { data: item } = await axios.get(
        `${COMPANIES.GET_COMPANY}/${user.companyId}`
      );

      return item.data;
    }
  });

  const {
    isLoadingWorkingHours,
    errorWorkingHours,
    data: WorkingHours,
  } = useQuery(["WorkingHours", user], async () => {
    if (user?.id) {
      const { data: item } = await axios.get(
        `${COMPANIES.GET_COMPANY}/working-hours/${user.companyId}`
      );
      setValue("WorkingHours", item.data);
      return item.data;
    }
  });

  const {
    isLoadingPayments,
    errorPayments,
    data: companyPayments,
  } = useQuery(["companyPayments"], async () => {
    const { data: item } = await axios.get(`${PAYMENTS.GET_PAYMENTS}`);

    item.data.Payments.forEach((item, index) => {
      setValue(
        `payments[${index}].taxPercentage`,
        item.PaymentCompany.taxPercentage
      );
      setValue(`payments[${index}].paymentId`, item.id);
    });

    return item.data;
  });

  useEffect(() => {
    if (company) {
      Object.keys(company).forEach((key) => {
        setValue(key, company[key]);
      });

      setPreviewUrl(company?.imageUrl);
    }
  }, [company]);

  const onSubmit = async (data) => {
    try {
      setIsSubmitting(true);

      if (avatarImg) {
        const formData = new FormData();
        formData.append("image", avatarImg);
        const { data: avatar } = await axios.post(
          `${AVATAR.UPLOAD}?avatar=${company?.avatar || ""}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        data.avatar = avatar.imageName;
      }

      await axios.put(`${COMPANIES.GET_COMPANY}`, data);
      setAlert({
        show: true,
        message: "Dados da empresa atualizados com sucesso!",
        type: "success",
      });
    } catch (error) {
      setErrorSubmit(error);
      setAlert({
        show: true,
        message: "Erro ao tentar atualizar dados da empresa!",
        type: "failure",
      });
    } finally {
      setIsSubmitting(false);
      setTimeout(() => {
        setAlert({
          show: false,
          message: "",
          type: "",
        });
      }, 2000);
    }
  };

  const onSubmitPayments = async (data) => {
    try {
      setIsSubmitting(true);
      await axios.put(`${PAYMENTS.GET_PAYMENTS}/taxes`, data.payments);
      setAlert({
        show: true,
        message: "Taxas de pagamento atualizadas com sucesso!",
        type: "success",
      });
    } catch (error) {
      setErrorSubmit(error);
      setAlert({
        show: true,
        message: "Erro ao tentar atualizar taxas de pagamento!",
        type: "failure",
      });
    } finally {
      setIsSubmitting(false);
      setTimeout(() => {
        setAlert({
          show: false,
          message: "",
          type: "",
        });
      }, 2000);
    }
  };

  return (
    <div className="bg-white px-4 py-4 rounded-md shadow-md">
      <Tabs aria-label="Default tabs" style="underline">
        <Tabs.Item active title="Empresa" icon={MdBusinessCenter}>
          <form className="mb-6" onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-6">
              <ImageInput
                image={avatarImg}
                setImage={setAvatarImg}
                previewUrl={previewUrl}
                setPreviewUrl={setPreviewUrl}
              />
            </div>
            <div className="mb-6">
              <Input
                type="text"
                label="Nome *"
                name="name"
                required={true}
                register={register}
                error={errors?.name && "Este campo é obrigatório"}
              />
            </div>
            <div className="mb-6">
              <Input
                type="email"
                label="E-mail *"
                name="email"
                required={true}
                register={register}
                error={errors?.email && "Este campo é obrigatório"}
              />
            </div>

            <div className="mb-6">
              <h2 className="mb-2">Horários de funcionamento:</h2>
              {!isLoadingWorkingHours ? (
                <>
                  {WorkingHours?.map((weekday, index) => (
                    <Card className="card-working-hours mb-2" key={index}>
                      <div className="flex gap-2 items-start flex-col">
                        <div className="flex items-center gap-2 justify-between w-full">
                          <Label value={weekdays[weekday.weekday]} />
                          <label className="inline-flex items-center cursor-pointer">
                            <input
                              type="checkbox"
                              className="sr-only peer"
                              name={`WorkingHours[${index}].isOpen`}
                              checked={watch(`WorkingHours[${index}].isOpen`)}
                              onChange={() => {
                                setValue(
                                  `WorkingHours[${index}].isOpen`,
                                  !watch(`WorkingHours[${index}].isOpen`)
                                );
                              }}
                            />
                            <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-red-600"></div>
                            <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">
                              Aberto?
                            </span>
                          </label>
                        </div>
                        <Input
                          type="hidden"
                          name={`WorkingHours[${index}].id`}
                          required={false}
                          register={register}
                          className="d-none"
                        />
                        <div className="flex gap-2 items-center w-full">
                          <Input
                            type="time"
                            label="Horário início"
                            name={`WorkingHours[${index}].startHour`}
                            required={false}
                            register={register}
                          />
                          <Input
                            type="time"
                            label="Horário fim"
                            name={`WorkingHours[${index}].endHour`}
                            required={false}
                            register={register}
                          />
                        </div>
                      </div>
                    </Card>
                  ))}
                </>
              ) : (
                <></>
              )}
            </div>

            <Button
              type="submit"
              className="w-full primary"
              isProcessing={isSubmitting}
              processingSpinner={
                <AiOutlineLoading className="h-6 w-6 animate-spin" />
              }
              disabled={isSubmitting}
            >
              Atualizar dados da empresa
            </Button>
            {alert.show && (
              <Alert className="mt-4" color={`${alert.type}`}>
                <span className="font-medium">{alert.message}</span>
              </Alert>
            )}
            <div className="mb-6 flex flex-col md:flex-row gap-4 mt-10">
              <Card className="w-full bg-gray-100">
                <span className="font-bold mx-auto">
                  Data de expiração do plano:{" "}
                </span>
                <span className="mx-auto">
                  {moment(company?.planExpirationDate).format("DD/MMMM/YYYY")}
                </span>
              </Card>
              <Card className="w-full bg-gray-100">
                <Badge className="w-fit mx-auto" size="sm" color="pink">
                  {company?.planId}
                </Badge>
                <span className="font-bold mx-auto">
                  Número de profissionais permitidos no plano:
                </span>
                <span className="bg-primary text-white rounded-md text-center w-fit px-4 py-2 mx-auto">
                  {company?.planId === "FREE" ? 1 : 10}
                </span>
              </Card>
            </div>

            {error && (
              <Alert color="failure" icon={HiInformationCircle}>
                Erro ao atualizar dados da empresa
              </Alert>
            )}
          </form>
        </Tabs.Item>
        <Tabs.Item title="Pagamentos" icon={MdOutlinePayments}>
          <form className="mb-10" onSubmit={handleSubmit(onSubmitPayments)}>
            {companyPayments?.Payments.map((payment, index) => (
              <div key={index} className="mb-4">
                <Input
                  name={`payments[${index}].paymentId`}
                  label={""}
                  type="hidden"
                  register={register}
                />
                <Input
                  name={`payments[${index}].taxPercentage`}
                  label={`Taxa ${payment.name} %`}
                  type="number"
                  register={register}
                  step="0.01"
                />
              </div>
            ))}
            <Button
              type="submit"
              className="w-full primary"
              isProcessing={isSubmitting}
              processingSpinner={
                <AiOutlineLoading className="h-6 w-6 animate-spin" />
              }
              disabled={isSubmitting}
            >
              Atualizar taxas da empresa
            </Button>
            {alert.show && (
              <Alert className="mt-4" color={`${alert.type}`}>
                <span className="font-medium">{alert.message}</span>
              </Alert>
            )}
          </form>
        </Tabs.Item>
      </Tabs>
    </div>
  );
};

export default CompanyPage;
