import Input from "../inputs/Input";
import { useForm, Controller } from "react-hook-form";
import {
  COMMANDS,
  CUSTOMERS,
  PROFESSIONALS,
  SERVICES,
  PRODUCTS,
  PACKAGES,
} from "../../config/apiConfig";
import axios from "../../config/axiosInstance";
import { useEffect, useState, useRef } from "react";
import { useApplication } from "../../contexts/ApplicationContext";
import { Alert, Badge, Button, Card } from "flowbite-react";
import { HiInformationCircle } from "react-icons/hi";
import AutoCompleteInput from "../inputs/Autocomplete";
import { useQuery } from "react-query";
import moment from "moment";
import CommandPaymentForm from "./CommandPaymentForm";
import { MdOutlineDelete } from "react-icons/md";
import { formatCurrencyBRL } from "../../utils/utils";
import DrawerLoading from "../DrawerLoading";

const CommandForm = ({
  command,
  isLoadingCommand = false,
  setCommand,
  paymentTaxes,
  fetchCommands,
  isDrawerOpen,
  onClose,
  type = "CREATE",
  selectedProfessionalId = 0,
}) => {
  const { user, userPermission } = useApplication();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const formRef = useRef(null);
  const [commandServices, setCommandServices] = useState([
    { serviceId: 0, quantity: 1, price: 0 },
  ]);
  const [commandProducts, setCommandProducts] = useState([
    { productId: 0, quantity: 1, priceSell: 0, stock: 0 },
  ]);
  const [key, setKey] = useState(0);
  const [error, setError] = useState(null);
  const [openModalPayment, setOpenModalPayment] = useState(false);
  const {
    control,
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm();

  const servicesList = watch("services");
  const productsList = watch("products");

  const {
    isLoading: isLoadingCustomers,
    error: errorCustomers,
    data: customers,
  } = useQuery(["customers"], async () => {
    const { data: items } = await axios.get(
      `${CUSTOMERS.GET_CUSTOMERS}?itemsPerPage=${-1}`
    );

    return items.data.data.map((item) => ({
      label: item.name,
      value: item.id,
    }));
  });

  const {
    isLoading: isLoadingServices,
    error: errorServices,
    data: services,
  } = useQuery(["services"], async () => {
    const { data: items } = await axios.get(
      `${SERVICES.GET_SERVICES}?itemsPerPage=${-1}`
    );

    return items.data.data.map((item) => ({
      label: item.name,
      value: item.id,
      price: item.price,
      typePrice: item.typePrice,
    }));
  });

  const {
    isLoading: isLoadingProducts,
    error: errorProducts,
    data: products,
  } = useQuery(["products"], async () => {
    const { data: items } = await axios.get(
      `${PRODUCTS.GET_PRODUCTS}?itemsPerPage=${-1}`
    );

    return items.data.data.map((item) => ({
      label: item.name,
      value: item.id,
      price: item.priceSell,
      stock: item.stock,
    }));
  });

  const {
    isLoading: isLoadingProfessionals,
    error: errorProfessionals,
    data: professionals,
  } = useQuery(["professionals"], async () => {
    const { data: items } = await axios.get(
      `${PROFESSIONALS.GET_PROFESSIONALS}?itemsPerPage=${-1}&isActive=1`
    );

    return items.data.data.map((item) => ({
      label: item.name,
      value: item.id,
    }));
  });

  const {
    isLoadingCustomerPackages,
    errorCustomerPackages,
    data: customerPackages,
  } = useQuery(
    ["customerPackages", watch("customerId")],
    async () => {
      const { data: items } = await axios.get(
        `${PACKAGES.GET_BY_CUSTOMER}/${watch("customerId")}?isActive=true`
      );

      return items.data;
    },
    {
      enabled: Boolean(watch("customerId")),
    }
  );

  const calculateTotalPrice = () => {
    let totalPrice = 0;

    servicesList?.forEach((service) => {
      if (service?.priceUnit && !service?.isPackage) {
        totalPrice += service.priceUnit * +service.quantity;
      }
    });

    productsList?.forEach((product) => {
      if (product?.priceUnit) {
        totalPrice += product.priceUnit * +product.quantity;
      }
    });

    return totalPrice - +watch("discount");
  };

  useEffect(() => {
    reset();
    setError(null);
    setCommandServices([]);
    setCommandProducts([]);

    if (type === "EDIT" && command) {
      Object.keys(command).forEach((key) => {
        setValue(key, command[key]);
      });

      if (
        command &&
        command?.Services &&
        Array.isArray(command.Services) &&
        command?.Products &&
        Array.isArray(command.Products)
      ) {
        command.Services.forEach((service, index) => {
          setValue(`services[${index}].serviceId`, service.id);
          setValue(
            `services[${index}].quantity`,
            service.CommandService.quantity
          );

          setValue(`services[${index}].typePrice`, service?.typePrice);

          setValue(
            `services[${index}].priceUnit`,
            service.CommandService?.priceUnit || service?.price
          );

          setValue(
            `services[${index}].ProfessionalId`,
            service.CommandService?.ProfessionalId
          );

          setValue(
            `services[${index}].isPackage`,
            service.CommandService?.isPackage
          );

          setCommandServices((prev) => [
            ...prev,
            {
              serviceId: service.id,
              quantity: service.CommandService.quantity,
              priceUnit: service.CommandService?.priceUnit || 0,
              professionalId: service.CommandService?.ProfessionalId,
            },
          ]);
        });

        command.Products.forEach((product, index) => {
          setValue(`products[${index}].productId`, product.id);
          setValue(
            `products[${index}].quantity`,
            product.CommandProduct.quantity
          );
          setValue(
            `products[${index}].priceUnit`,
            product.CommandProduct.priceUnit
          );

          setValue(
            `products[${index}].ProfessionalId`,
            product.CommandProduct?.ProfessionalId
          );

          setValue(`products[${index}].stock`, product?.stock);

          setCommandProducts((prev) => [
            ...prev,
            {
              productId: product.id,
              quantity: product.CommandProduct.quantity,
              price: product.CommandProduct.priceUnit,
              professionalId: product.CommandProduct?.ProfessionalId,
              stock: product?.stock,
            },
          ]);
        });
      }
      setKey((prev) => prev + 1);
    } else {
      setValue("date", moment(new Date()).format("YYYY-MM-DD"));
      setCommandServices([]);
      setCommandProducts([]);
    }
  }, [command, isDrawerOpen]);

  const onSubmit = async (data) => {
    try {
      setIsSubmitting(true);
      data.totalPrice = calculateTotalPrice();

      if (data.discount === "") delete data.discount;

      if (type === "CREATE") {
        await axios.post(COMMANDS.GET_COMMAND, data);
      } else {
        delete data.payments;
        await axios.put(`${COMMANDS.GET_COMMAND}/${command.id}`, data);
      }
      setError(null);
      fetchCommands();
      onClose(false);
      if (type === "CREATE") reset();
    } catch (error) {
      setError(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleAddService = () => {
    let sizeArray = commandServices.length;

    setCommandServices((prev) => [
      ...prev,
      {
        serviceId: 0,
        quantity: 1,
        priceUnit: 0,
        ProfessionalId: selectedProfessionalId,
      },
    ]);

    if (selectedProfessionalId !== 0) {
      setValue(`services[${sizeArray}].ProfessionalId`, selectedProfessionalId);
    }
  };

  const handleDeleteService = (indexToRemove) => {
    setCommandServices((prev) =>
      prev?.filter((_, index) => index !== indexToRemove)
    );

    setValue(
      `services`,
      servicesList?.filter((_, index) => index !== indexToRemove)
    );
  };

  const handleAddProduct = () => {
    let sizeArray = commandProducts.length;

    setCommandProducts((prev) => [
      ...prev,
      {
        serviceId: 0,
        quantity: 1,
        priceUnit: 0,
        ProfessionalId: selectedProfessionalId,
        stock: 0,
      },
    ]);

    if (selectedProfessionalId !== 0) {
      setValue(`products[${sizeArray}].ProfessionalId`, selectedProfessionalId);
    }
  };

  const handleDeleteProduct = (indexToRemove) => {
    setCommandProducts((prev) =>
      prev?.filter((_, index) => index !== indexToRemove)
    );

    setValue(
      `products`,
      productsList?.filter((_, index) => index !== indexToRemove)
    );
  };

  const renderSwitchPackageService = (serviceId, professionalId, index) => {
    let foundPackage = null;

    if (customerPackages?.length > 0) {
      customerPackages.some((cp) => {
        foundPackage = cp.Services?.find((service) => {
          return (
            service.PackageService?.ProfessionalId === +professionalId &&
            service.PackageService?.ServiceId === +serviceId &&
            service.PackageService?.quantity >
              service.PackageService?.quantityUsed
          );
        });

        return foundPackage;
      });
    }

    if (foundPackage) {
      setValue(
        `services[${index}].PackageId`,
        foundPackage?.PackageService.PackageId
      );
      return (
        <div className="flex items-center justify-between">
          <label className="inline-flex items-center me-5 cursor-pointer">
            <input
              type="checkbox"
              className="sr-only peer"
              name={`services[${index}].isPackage`}
              checked={watch(`services[${index}].isPackage`)}
              onChange={() => {
                setValue(
                  `services[${index}].isPackage`,
                  !watch(`services[${index}].isPackage`)
                );
              }}
            />
            <div className="relative w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-green-300 dark:peer-focus:ring-green-800 dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-green-700"></div>
            <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">
              Usar serviço do pacote?
            </span>
          </label>
          <div className="font-semibold text-sm md:text-base">
            Saldo:{" "}
            {+foundPackage?.PackageService.quantity -
              +foundPackage?.PackageService.quantityUsed}
          </div>
        </div>
      );
    } else {
      return null;
    }
  };

  return (
    <>
      <form className="mb-6" onSubmit={handleSubmit(onSubmit)} ref={formRef}>
        <div className="mb-6">
          <Input
            type="date"
            label="Data da comanda *"
            name="date"
            required={true}
            register={register}
            error={errors.date && "Este campo é obrigatório"}
            disabled={watch("statusId") === 1}
          />
          {errors && (
            <small className="text-red-400">{errors.date?.message}</small>
          )}
        </div>
        <div className="mb-6">
          <Controller
            name="customerId"
            control={control}
            rules={{ required: "Este campo é obrigatório" }}
            render={({ field }) => (
              <AutoCompleteInput
                label="Selecione o cliente *"
                options={customers}
                onChange={(selectedOption) =>
                  field.onChange(selectedOption.value)
                }
                placeholder=""
                isLoading={isLoadingCustomers}
                value={field.value}
                disabled={watch("statusId") === 1}
              />
            )}
          />
          {errors && (
            <small className="text-red-400">{errors.customerId?.message}</small>
          )}
        </div>
        {customerPackages?.length > 0 && watch("statusId") !== 1 && (
          <div className="rounded-sm bg-gray-100 text-sm px-2 py-1 -mt-4">
            Este cliente possui {customerPackages.length} pacotes de serviços
            que podem ser utilizados
          </div>
        )}
        {watch("statusId") !== 1 && (
          <div className="mt-6">
            <h3 className="mb-3 font-semibold">
              Serviços ({commandServices.length})
            </h3>
            {commandServices.map((field, index) => (
              <Card className="mb-3" key={index}>
                <div className="flex gap-2 flex-col md:flex-row">
                  <Controller
                    name={`services[${index}].serviceId`}
                    control={control}
                    render={({ field }) => (
                      <AutoCompleteInput
                        label="Serviço *"
                        options={services}
                        onChange={(selectedOption) => {
                          field.onChange(selectedOption.value);
                          const service = services.find(
                            (service) => selectedOption.value == service.value
                          );

                          setValue(
                            `services[${index}].priceUnit`,
                            service.price
                          );
                          setValue(
                            `services[${index}].typePrice`,
                            service.typePrice
                          );
                        }}
                        placeholder=""
                        isLoading={isLoadingServices}
                        value={watch(`services[${index}].serviceId`)}
                        disabled={watch("statusId") === 1}
                      />
                    )}
                  />
                  <div className="flex gap-2 w-full items-center mt-2 md:mt-0">
                    <Input
                      className="w-full"
                      type="number"
                      register={register}
                      label="Quantidade"
                      name={`services[${index}].quantity`}
                      value={watch(`services[${index}].quantity`)}
                      defaultValue={1}
                      disabled={watch("statusId") === 1}
                    />
                    <div className="text-sm w-full">
                      <Input
                        className="w-full"
                        type="number"
                        register={register}
                        label="Preço Unit."
                        name={`services[${index}].priceUnit`}
                        value={watch(`services[${index}].priceUnit`)}
                        defaultValue={0}
                        disabled={
                          watch("statusId") === 1 ||
                          watch(`services[${index}].typePrice`) === "F"
                        }
                        step="0.01"
                      />
                    </div>
                    {commandServices.length > 0 && watch("statusId") !== 1 && (
                      <Button
                        className="bg-gray-500 flex items-center justify-center"
                        onClick={() => handleDeleteService(index)}
                      >
                        <MdOutlineDelete className="text-lg h-7" />
                      </Button>
                    )}
                  </div>
                </div>
                <div>
                  <Controller
                    name={`services[${index}].ProfessionalId`}
                    control={control}
                    rules={{ required: "Este campo é obrigatório" }}
                    render={({ field }) => (
                      <AutoCompleteInput
                        label="Selecione o profissional *"
                        options={professionals}
                        onChange={(selectedOption) => {
                          field.onChange(selectedOption.value);
                          setValue(`services[${index}].isPackage`, false);
                        }}
                        placeholder=""
                        isLoading={isLoadingProfessionals}
                        value={field.value}
                        disabled={watch("statusId") === 1}
                      />
                    )}
                  />
                  {errors?.services?.[index]?.ProfessionalId && (
                    <small className="text-red-400">
                      {errors.services[index].ProfessionalId.message}
                    </small>
                  )}
                </div>
                {renderSwitchPackageService(
                  watch(`services[${index}].serviceId`),
                  watch(`services[${index}].ProfessionalId`),
                  index
                )}
              </Card>
            ))}
          </div>
        )}
        {watch("statusId") !== 1 && (
          <>
            <div className="flex mt-3 mb-6">
              <Button
                className="primary shadow"
                size="xs"
                onClick={handleAddService}
              >
                Adicionar serviço
              </Button>
            </div>
          </>
        )}
        {watch("statusId") !== 1 && (
          <div className="mt-6">
            <h3 className="mb-3 font-semibold">
              Produtos ({commandProducts.length})
            </h3>
            {commandProducts.map((field, index) => (
              <Card className="mb-3" key={index}>
                <div className="flex gap-2 flex-col md:flex-row">
                  <Controller
                    name={`products[${index}].productId`}
                    control={control}
                    render={({ field }) => (
                      <AutoCompleteInput
                        label="Produto *"
                        options={products}
                        onChange={(selectedOption) => {
                          field.onChange(selectedOption.value);
                          setValue(
                            `products[${index}].priceUnit`,
                            products.find(
                              (product) => selectedOption.value == product.value
                            ).price
                          );
                          setValue(
                            `products[${index}].stock`,
                            products.find(
                              (product) => selectedOption.value == product.value
                            ).stock
                          );
                        }}
                        placeholder=""
                        isLoading={isLoadingProducts}
                        value={watch(`products[${index}].productId`)}
                        disabled={watch("statusId") === 1}
                      />
                    )}
                  />
                  <div className="flex gap-2 w-full items-center mt-2 md:mt-0">
                    <Input
                      className="w-full"
                      type="number"
                      register={register}
                      label="Quantidade"
                      name={`products[${index}].quantity`}
                      value={watch(`products[${index}].quantity`)}
                      defaultValue={1}
                      disabled={watch("statusId") === 1}
                    />
                    <div className="text-sm w-full">
                      <Input
                        className="w-full"
                        type="number"
                        register={register}
                        label="Preço Unit."
                        name={`products[${index}].priceUnit`}
                        value={watch(`products[${index}].priceUnit`)}
                        defaultValue={0}
                        disabled={watch("statusId") === 1}
                        step="0.01"
                      />
                    </div>

                    {commandProducts.length > 0 && watch("statusId") !== 1 && (
                      <Button
                        className="bg-gray-500 flex items-center justify-center"
                        onClick={() => handleDeleteProduct(index)}
                      >
                        <MdOutlineDelete className="text-lg h-7" />
                      </Button>
                    )}
                  </div>
                </div>
                <div className="flex gap-2">
                  <div className="w-full">
                    <Controller
                      name={`products[${index}].ProfessionalId`}
                      control={control}
                      rules={{ required: "Este campo é obrigatório" }}
                      render={({ field }) => (
                        <AutoCompleteInput
                          label="Selecione o profissional *"
                          options={professionals}
                          onChange={(selectedOption) =>
                            field.onChange(selectedOption.value)
                          }
                          placeholder=""
                          isLoading={isLoadingProfessionals}
                          value={field.value}
                          disabled={watch("statusId") === 1}
                        />
                      )}
                    />
                    {errors?.products?.[index]?.ProfessionalId && (
                      <small className="text-red-400">
                        {errors.products[index].ProfessionalId.message}
                      </small>
                    )}
                  </div>
                  <div className="text-sm w-full">
                    <Input
                      className="w-full"
                      type="number"
                      register={register}
                      label="Qtd. em estoque"
                      name={`products[${index}].stock`}
                      value={watch(`products[${index}].stock`)}
                      defaultValue={0}
                      disabled={true}
                    />
                  </div>
                </div>
                {watch(`products[${index}].stock`) <
                  watch(`products[${index}].quantity`) && (
                  <Alert color="failure">
                    Sem estoque disponível para o produto selecionado.
                  </Alert>
                )}
              </Card>
            ))}
          </div>
        )}
        {watch("statusId") !== 1 && (
          <>
            <div className="flex mt-3 mb-6">
              <Button
                className="primary shadow"
                size="xs"
                onClick={handleAddProduct}
              >
                Adicionar produto
              </Button>
            </div>
          </>
        )}
        {watch("statusId") !== 1 && (
          <>
            <div className="mb-6 flex gap-2">
              <Input
                type="number"
                label="Descontos R$"
                name="discount"
                required={false}
                register={register}
                step="0.01"
              />
              <div className="flex w-full md:w-3/5  flex-col">
                <span>Total da comanda:</span>
                <span className="font-bold">
                  {" "}
                  {formatCurrencyBRL.format(calculateTotalPrice() || 0)}
                </span>
              </div>
            </div>
          </>
        )}
        {watch("statusId") === 1 && (
          <div className="mb-10">
            <div className="font-bold">Detalhes da comanda</div>
            <div className="text-sm mt-2 flex flex-col md:flex-row gap-1 items-start md:items-center">
              <div>Data e hora do pagamento: </div>
              <Badge className="w-fit">
                {moment(command?.updatedAt).format("DD/MM/yyyy HH:mm")}
              </Badge>
            </div>
            {command?.Services.length > 0 && (
              <div className="font-semibold text-sm mt-4">
                Serviços Realizados:
              </div>
            )}

            {command?.Services.map((service, index) => (
              <div
                key={index}
                className="flex gap-3 my-3 justify-between items-center"
              >
                <div className="flex flex-col">
                  <span className="text-xs md:text-base">{service.name}</span>
                </div>
                <div className="flex gap-3">
                  {service.CommandService.isPackage && (
                    <Badge color="gray">Pacote</Badge>
                  )}
                  <Badge color="gray">
                    Qtd. {service.CommandService.quantity}
                  </Badge>
                  <Badge className="w-fit" color="green">
                    {!service.CommandService.isPackage
                      ? `Unid. ${formatCurrencyBRL.format(
                          service.CommandService?.priceUnit
                        )}`
                      : "R$ 0"}
                  </Badge>
                  <span className="flex gap-2 items-center">
                    {professionals.length > 0 && (
                      <Badge>
                        {
                          professionals.find(
                            (professional) =>
                              professional.value ===
                              service.CommandService?.ProfessionalId
                          )?.label
                        }
                      </Badge>
                    )}
                  </span>
                </div>
              </div>
            ))}

            {command?.Products.length > 0 && (
              <div className="font-semibold text-sm mt-4">
                Produtos comprados:
              </div>
            )}

            {command?.Products.map((product, index) => (
              <div
                key={index}
                className="flex gap-3 my-3 justify-between items-center"
              >
                <div className="flex flex-col">
                  <span className="text-xs md:text-base">{product.name}</span>
                </div>
                <div className="flex gap-3">
                  <Badge color="gray" className="w-fit">
                    Qtd. {product.CommandProduct.quantity}
                  </Badge>
                  <Badge className="w-fit" color="green">
                    Unid.{" "}
                    {formatCurrencyBRL.format(product.CommandProduct.priceUnit)}
                  </Badge>
                  <span className="flex gap-2 items-center">
                    {professionals.length > 0 && (
                      <Badge>
                        {
                          professionals.find(
                            (professional) =>
                              professional.value ===
                              product.CommandProduct?.ProfessionalId
                          )?.label
                        }
                      </Badge>
                    )}
                  </span>
                </div>
              </div>
            ))}

            <div className="font-semibold text-sm mt-5">
              Métodos de pagamento
            </div>
            {watch("Payments")?.map((payment, index) => (
              <div
                key={index}
                className="flex gap-2 my-3 justify-between w-full"
              >
                <div>
                  <span className="text-xs md:text-base">{payment.name}</span>
                </div>
                <div className="flex gap-4">
                  <span className="w-fit text-xs md:text-sm">
                    Tx. {payment.CommandPayment?.taxPercentage}%
                  </span>
                  <span className="w-fit text-xs md:text-sm">
                    {formatCurrencyBRL.format(payment.CommandPayment?.price)}
                  </span>
                </div>
              </div>
            ))}
            <div className=""></div>
          </div>
        )}
        {watch("statusId") === 1 && (
          <>
            <div className="text-red-500 mb-2 font-semibold">
              Descontos: {formatCurrencyBRL.format(command?.discount || 0)}
            </div>
            <div className="font-bold mb-4">
              Total: {formatCurrencyBRL.format(command?.totalPrice || 0)}
            </div>
          </>
        )}
        <div className="mb-6">
          <Input
            type="text"
            label="Observação"
            name="observation"
            required={false}
            register={register}
          />
        </div>
        {watch("statusId") !== 1 && (
          <Button
            type="submit"
            className="w-full primary shadow"
            disabled={
              isSubmitting ||
              (commandServices.length < 1 && commandProducts.length < 1)
            }
          >
            {type === "CREATE" ? "Criar" : "Salvar"} comanda
          </Button>
        )}
        {type === "EDIT" && watch("statusId") !== 1 && (
          <Button
            className="w-full mt-2 bg-green-500 hover:bg-green-600 shadow"
            disabled={
              isSubmitting ||
              (commandServices.length === 0 && commandProducts.length === 0)
            }
            onClick={() => setOpenModalPayment(true)}
          >
            Faturar comanda
          </Button>
        )}
      </form>
      {error && (
        <Alert color="failure" icon={HiInformationCircle}>
          Erro ao {error?.config?.method === "put" ? "atualizar" : "criar"}{" "}
          comanda
        </Alert>
      )}
      <CommandPaymentForm
        command={command}
        setCommand={setCommand}
        paymentTaxes={paymentTaxes}
        fetchCommands={fetchCommands}
        openModal={openModalPayment}
        setOpenModal={setOpenModalPayment}
        setOpenParentModal={onClose}
        setValue={setValue}
        register={register}
        watch={watch}
        totalPrice={calculateTotalPrice()}
      />
      {(isLoadingCommand ||
        isLoadingCustomerPackages ||
        isLoadingCustomers ||
        isLoadingProducts ||
        isLoadingServices ||
        isLoadingProfessionals) && <DrawerLoading />}
    </>
  );
};

export default CommandForm;
