import { Button, Modal } from "flowbite-react";
import { HiOutlineExclamationCircle } from "react-icons/hi";
import { useApplication } from "../../contexts/ApplicationContext";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";

const ModalConfirmAdditionalPlan = () => {
  const { userPermission, user } = useApplication();

  const [isOpen, setOpenModal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (user?.Company?.planId === "FREE") {
      setOpenModal(true);
    }
  }, [user]);

  return (
    <>
      <Modal show={isOpen} size="md" onClose={() => navigate(-1)} popup>
        <Modal.Header />
        <Modal.Body>
          <div className="text-center">
            {userPermission === "ADMIN" ? (
              <>
                <div className="font-semibold text-gray-600">
                  Desbloqueie o Plano Pro da Relinq e leve sua gestão a outro
                  nível! Por apenas R$29,90! ⭐
                </div>
                <div className="mb-5 mt-2 text-sm">
                  Saiba as vantagens de ser um Relinqer Pro:
                </div>
                <ul className="text-left flex flex-col gap-1 mb-2 text-sm bg-gray-100 rounded p-2">
                  <li className="flex gap-1 items-center">
                    <IoMdCheckmarkCircleOutline
                      color="green"
                      style={{ minWidth: "20px", fontSize: "15px" }}
                    />{" "}
                    <span>
                      Até <span className="font-semibold">10 agendas</span>{" "}
                      simultâneas
                    </span>
                  </li>
                  <li className="flex gap-1 items-center">
                    <IoMdCheckmarkCircleOutline
                      color="green"
                      style={{ minWidth: "20px", fontSize: "15px" }}
                    />{" "}
                    <span>
                      <span className="font-semibold">
                        Lembretes automáticos
                      </span>{" "}
                      para seus clientes
                    </span>
                  </li>
                  <li className="flex gap-1 items-center">
                    <IoMdCheckmarkCircleOutline
                      color="green"
                      style={{ minWidth: "20px", fontSize: "15px" }}
                    />{" "}
                    <span>
                      <span className="font-semibold">Pacotes personalizados</span> para oferecer mais opções de
                      serviços
                    </span>
                  </li>

                  <li className="flex gap-1 items-center">
                    <IoMdCheckmarkCircleOutline
                      color="green"
                      style={{ minWidth: "20px", fontSize: "15px" }}
                    />{" "}
                    <span>
                      <span className="font-semibold">Gestão de faturamento</span> para facilitar a sua rotina e controle financeiro
                    </span>
                  </li>
                  <li className="flex gap-1 items-center">
                    <IoMdCheckmarkCircleOutline
                      color="green"
                      style={{ minWidth: "20px", fontSize: "15px" }}
                    />{" "}
                    <span>
                      <span className="font-semibold">Formulários</span> para coleta de informações
                      essenciais de seus clientes
                    </span>
                  </li>
                </ul>
                <div className="flex justify-center gap-2">
                  <Button
                    className="bg-green-500"
                    onClick={() => navigate("/payment")}
                  >
                    Contratar agora
                  </Button>
                </div>
              </>
            ) : (
              <>
                <HiOutlineExclamationCircle className="mx-auto mb-2 h-14 w-14 text-red-500 dark:text-gray-200" />
                <h3 className="mb-2 text-lg font-normal text-red-500 dark:text-red-500 font-semibold">
                  Ops, acesso não disponível!
                </h3>
                <div className="font-semibold text-gray-600">
                  Contate o administrador dessa empresa para contratar essa
                  funcionalidade.
                </div>
                <div className="flex justify-center gap-2">
                  <Button className="bg-gray-500" onClick={() => navigate(-1)}>
                    Fechar
                  </Button>
                </div>
              </>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ModalConfirmAdditionalPlan;
