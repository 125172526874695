import Input from "../inputs/Input";
import { useForm, Controller } from "react-hook-form";
import {
  APPOINTMENTS,
  COMMANDS,
  CUSTOMERS,
  PAYMENTS,
  PROFESSIONALS,
  SERVICES,
} from "../../config/apiConfig";
import { useApplication } from "../../contexts/ApplicationContext";
import axios from "../../config/axiosInstance";
import { useEffect, useState } from "react";
import { Alert, Button, Card, Modal } from "flowbite-react";
import SelectInput from "../inputs/SelectInput";
import { useQuery, useQueryClient } from "react-query";
import {
  addMinutesToHours,
  calculateTimeService,
  renderHourOptions,
  resetValuesForm,
} from "../../utils/utils";
import AutoCompleteInput from "../inputs/Autocomplete";
import { AiOutlineLoading } from "react-icons/ai";
import {
  FaRegCalendarCheck,
  FaRegCalendarMinus,
  FaRegCalendarTimes,
  FaWhatsapp,
} from "react-icons/fa";
import PhoneInput from "../inputs/PhoneInput";
import Drawer from "../Drawer";
import CommandForm from "./CommandForm";
import { MdNotificationsActive, MdOutlineDelete } from "react-icons/md";
import { HiInformationCircle, HiUserAdd } from "react-icons/hi";
import moment from "moment";
import { formatWhatsappMessage } from "../../config/whatsapp-template-msg";
import CustomerInfosForm from "./CustomerInfosForm";
import { PiUserListLight } from "react-icons/pi";
import DrawerLoading from "../DrawerLoading";
import {
  LiaFileInvoiceDollarSolid,
  LiaFileInvoiceSolid,
} from "react-icons/lia";

const AppointmentForm = ({
  appointment,
  isLoadingAppointment,
  fetchAppointments,
  selectedProfessionalId,
  selectedDate,
  isDrawerOpen,
  onClose,
  type = "CREATE",
  selectedHour,
  selectedEndHour,
  isTypeBlock,
}) => {
  const [, setKey] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState(false);
  const [isSubmittingCustomer, setIsSubmittingCustomer] = useState(false);
  const [openCommand, setOpenCommand] = useState(false);
  const { user, userPermission } = useApplication();
  const [isQueryEnabledCommand, setIsQueryEnabledCommand] = useState(false);
  const [isQueryEnabledPaymentTaxes, setIsQueryEnabledPaymentTaxes] =
    useState(false);
  const [isQueryEnabledCancellations, setIsQueryEnabledCancellations] =
    useState(false);
  const [isDrawerOpenCommand, setIsDrawerOpenCommand] = useState(false);
  const [isDrawerInfosOpen, setIsDrawerInfosOpen] = useState(false);
  const [shouldFetchCustomerInfos, setShouldFetchCustomerInfos] =
    useState(false);
  const [openModalFastRegisterCustomer, onCloseModalFastRegisterCustomer] =
    useState(false);

  const queryClient = useQueryClient();
  const [appointmentServices, setAppointmentServices] = useState([
    { AppointmentService: { ServiceId: 0, quantity: "1" } },
  ]);

  const {
    control,
    register,
    handleSubmit,
    reset,
    setError,
    setValue,
    watch,
    formState: { errors },
  } = useForm();

  const {
    register: registerCustomer,
    handleSubmit: handleSubmitCustomer,
    reset: resetCustomer,
    setError: setErrorCustomer,
    formState: { errors: errorsCustomer },
  } = useForm();

  const {
    isLoading: isLoadingCustomers,
    error: errorCustomers,
    data: customersAppointment,
  } = useQuery(["customersAppointment"], async () => {
    const { data: items } = await axios.get(
      `${CUSTOMERS.GET_CUSTOMERS}?itemsPerPage=${-1}`
    );

    return items.data.data.map((item) => ({
      label: item.name,
      value: item.id,
    }));
  });

  const {
    isLoading: isLoadingServices,
    error: errorServices,
    data: servicesAppointment,
  } = useQuery(["servicesAppointment"], async () => {
    const { data: items } = await axios.get(
      `${SERVICES.GET_SERVICES}?itemsPerPage=${-1}`
    );

    return items.data.data.map((item) => ({
      label: item.name,
      value: item.id,
      durationMinutes: item.durationMinutes,
    }));
  });

  const shouldFetchProfessionals = userPermission !== "USER";

  const {
    isLoading: isLoadingProfessionals,
    error: errorProfessionals,
    data: professionalsAppointment,
  } = useQuery(
    ["professionalsAppointment"],
    async () => {
      const { data: items } = await axios.get(
        `${PROFESSIONALS.GET_PROFESSIONALS}?itemsPerPage=-1&isActive=1`
      );

      return items.data.data.map((item) => ({
        label: item.name,
        value: item.id,
      }));
    },
    { enabled: shouldFetchProfessionals }
  );

  const {
    isLoading: isLoadingCommand,
    error: errorCommand,
    data: selectedCommand,
  } = useQuery(
    ["selectedCommand"],
    async () => {
      const { data: item } = await axios.get(
        `${COMMANDS.GET_COMMAND}/${appointment.commandId}`
      );

      return item.data;
    },
    { enabled: isQueryEnabledCommand }
  );

  const {
    isLoadingPaymentTaxes,
    errorPaymentTaxes,
    data: paymentTaxes,
  } = useQuery(
    ["paymentTaxes"],
    async () => {
      const { data: items } = await axios.get(`${PAYMENTS.GET_PAYMENTS}`);

      return items.data.Payments.map((payment) => ({
        id: payment.PaymentCompany.PaymentId,
        taxPercentage: payment.PaymentCompany.taxPercentage,
      }));
    },
    { enabled: isQueryEnabledPaymentTaxes }
  );

  const {
    isLoadingCancellations,
    errorCancellations,
    data: cancellations,
  } = useQuery(
    ["cancellations"],
    async () => {
      const { data: items } = await axios.get(
        `${APPOINTMENTS.GET_CANCELLATIONS}`
      );

      return items.data;
    },
    { enabled: isQueryEnabledCancellations }
  );

  useEffect(() => {
    if (type === "CREATE") {
      setValue("startHour", selectedHour);
      setValue("endHour", selectedEndHour);
    }
  }, [selectedHour, selectedEndHour]);

  useEffect(() => {
    resetValuesForm(
      ["serviceId", "professionalId", "customerId"],
      setValue,
      reset
    );
    setSubmitError(false);

    if (type === "EDIT" && appointment) {
      Object.keys(appointment).forEach((key) => {
        setValue(key, appointment[key]);
      });
      setAppointmentServices(appointment?.Services);

      setKey((prev) => prev + 1);
    } else {
      setValue("date", selectedDate);
      setValue("statusId", "0");
      setValue("startHour", selectedHour || "07:00");
      setValue(
        "endHour",
        isTypeBlock === 0 ? selectedEndHour : appointment?.endHourTime
      );
      setValue("isBlock", isTypeBlock);
      setAppointmentServices([
        { AppointmentService: { ServiceId: 0, quantity: 1 } },
      ]);
      setValue("Services[0].AppointmentService.quantity", 1);
    }

    setIsQueryEnabledCommand(false);
    setIsQueryEnabledPaymentTaxes(false);
  }, [appointment, type, isDrawerOpen]);

  useEffect(() => {
    setValue("date", selectedDate);
  }, [selectedDate]);

  useEffect(() => {
    if (type === "CREATE" && selectedProfessionalId)
      setValue("professionalId", selectedProfessionalId);
  }, [selectedProfessionalId, isDrawerOpen]);

  useEffect(() => {
    if (watch("statusId") == -1) {
      setIsQueryEnabledCancellations(true);
    }
  }, [watch("statusId")]);

  const deleteAppointment = async (e) => {
    try {
      e.preventDefault();
      setIsSubmitting(true);

      await axios.delete(`${APPOINTMENTS.GET_APPOINTMENT}/${appointment.id}`);

      onClose(false);

      fetchAppointments();
    } catch (error) {
      console.error(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const onSubmit = async (data) => {
    try {
      setIsSubmitting(true);

      if (data.statusId == -1 && data.cancellationId == 0) {
        setError("cancellationId", {
          type: "manual",
          message: "O motivo do cancelamento é obrigatório!",
        });
        return;
      }

      let commandInfo;

      if (openCommand) {
        const commandData = {
          date: data.date,
          endHour: data.endHour,
          statusId: 0,
          discount: 0,
          totalPrice: 0,
          customerId: data.customerId,
        };

        commandData.services = data.Services.map((service) => ({
          serviceId: service.AppointmentService.ServiceId,
          quantity: +service.AppointmentService.quantity,
          priceUnit: service.price,
          ProfessionalId: data.professionalId,
        }));

        commandInfo = await axios.post(COMMANDS.GET_COMMAND, commandData);
        setOpenCommand(false);
      }

      if (!data.isBlock) {
        data.endHour = addMinutesToHours(data.startHour, data.endHour);
      } else {
        data.endHour = data.endHourTime;
      }

      if (commandInfo) {
        data.commandId = commandInfo.data.id;
      }

      if (data.isBlock) {
        delete data.customerId;
        delete data.serviceId;
      }

      if (data.statusId != -1) {
        data.cancellationId = null;
      }

      if (type === "CREATE") {
        await axios.post(APPOINTMENTS.GET_APPOINTMENT, data);
      } else {
        await axios.put(
          `${APPOINTMENTS.GET_APPOINTMENT}/${appointment.id}`,
          data
        );
      }

      onClose(false);

      fetchAppointments();

      if (type === "CREATE") {
        const date = watch("date");

        resetValuesForm(
          ["serviceId", "professionalId", "customerId"],
          setValue,
          reset
        );

        setValue("date", date);
      }
    } catch (error) {
      console.error(error);
      setSubmitError(true);
    } finally {
      setIsSubmitting(false);
    }
  };

  const onSubmitCreateCustomer = async (data) => {
    try {
      setIsSubmittingCustomer(true);

      const newCustomer = await axios.post(CUSTOMERS.GET_CUSTOMER, data);

      const currentCustomers = queryClient.getQueryData("customersAppointment");

      const updatedCustomers = [
        ...currentCustomers,
        { label: newCustomer.data.name, value: newCustomer.data.id },
      ];

      queryClient.setQueryData("customersAppointment", updatedCustomers);

      resetCustomer();

      setValue("customerId", newCustomer.data.id);

      onCloseModalFastRegisterCustomer(false);
    } catch (error) {
      console.error(error);
    } finally {
      setIsSubmittingCustomer(false);
    }
  };

  const handleAddService = () => {
    setAppointmentServices((prev) => [
      ...prev,
      {
        AppointmentService: {
          ServiceId: 0,
          quantity: 1,
        },
      },
    ]);

    setValue(
      `Services[${watch("Services").length}].AppointmentService.quantity`,
      1
    );
  };

  const handleDeleteService = (indexToRemove) => {
    setAppointmentServices((prev) =>
      prev.filter((_, index) => index !== indexToRemove)
    );

    setValue(
      "Services",
      watch("Services").filter((_, index) => index !== indexToRemove)
    );
  };

  const handleAttributeCommand = (e, appointmentId) => {
    e.preventDefault();
  };

  const handleFinishCommand = (e) => {
    e.preventDefault();
    setIsQueryEnabledCommand(true);
    setIsQueryEnabledPaymentTaxes(true);
    setIsDrawerOpenCommand(true);
  };

  const handleViewInfosCustomer = (e) => {
    e.preventDefault();
    setIsDrawerInfosOpen(true);
    setShouldFetchCustomerInfos(true);
  };

  const compareDates = (appointment) => {
    const now = moment();
    const specificDateTime = moment(
      `${appointment.date} ${appointment.startHour}`,
      "YYYY-MM-DD HH:mm:ss"
    );

    if (now.isAfter(specificDateTime)) {
      return false;
    }

    return true;
  };

  useEffect(() => {
    setValue("endHourTime", watch("startHour"));
  }, [watch("startHour")]);

  return (
    <>
      <form className="mb-6" onSubmit={handleSubmit(onSubmit)}>
        {type === "CREATE" && (
          <input
            type="checkbox"
            className="sr-only"
            name={`isBlock`}
            {...register(`isBlock`)}
            checked={watch(`isBlock`)}
          />
        )}

        <div className="flex flex-col md:flex-row gap-2 mb-6">
          {type === "EDIT" &&
            appointment?.statusId == 0 &&
            !watch("isBlock") &&
            compareDates(appointment) && (
              <a
                href={`https://wa.me/55${appointment.phoneNumber?.replace(
                  /[\(\)\s,-]/g,
                  ""
                )}?text=${formatWhatsappMessage(
                  appointment?.customerName,
                  appointment?.id
                )}`}
                target="_blank"
                className="bg-green-600 w-full flex justify-center text-white shadow text-sm rounded py-2"
              >
                <div className="flex gap-2 items-center">
                  <span>Enviar lembrete de agendamento</span>
                  <MdNotificationsActive fontSize={18} />
                </div>
              </a>
            )}

          {appointment?.phoneNumber && type === "EDIT" && !watch("isBlock") && userPermission !== "USER" && (
            <a
              className="bg-green-600 w-full flex justify-center text-white shadow text-sm rounded py-2 items-center gap-2"
              rel="noreferrer"
              target="_blank"
              href={`https://wa.me/55${appointment?.phoneNumber.replace(
                /[\(\)\s,-]/g,
                ""
              )}`}
            >
              <span>Falar no Whatsapp</span>
              <FaWhatsapp fontSize={18} />
            </a>
          )}
        </div>

        <div className="mb-6">
          <Input
            type="date"
            label={`Data de ${
              !watch("isBlock") ? "agendamento" : "bloqueio"
            } *`}
            name="date"
            required={true}
            register={register}
            error={errors.date && "Este campo é obrigatório"}
          />
          {errors && (
            <small className="text-red-400">{errors.date?.message}</small>
          )}
        </div>

        {!watch(`isBlock`) && (
          <div className="flex mb-6 gap-2">
            <div className="w-full">
              <Controller
                name="customerId"
                control={control}
                rules={{ required: "Este campo é obrigatório" }}
                render={({ field }) => (
                  <AutoCompleteInput
                    label="Selecione o cliente *"
                    options={customersAppointment}
                    onChange={(selectedOption) =>
                      field.onChange(selectedOption.value)
                    }
                    placeholder=""
                    isLoading={isLoadingCustomers}
                    value={field.value}
                    noOptionsMessage={
                      <div>
                        <span className="mb-2 block">
                          Nenhum cliente encontrado.
                        </span>
                        <button
                          className="bg-primary px-2 py-1 rounded-md text-white w-full"
                          onClick={(e) => {
                            e.preventDefault();
                            onCloseModalFastRegisterCustomer(true);
                          }}
                        >
                          Cadastro rápido
                        </button>
                      </div>
                    }
                  />
                )}
              />
              {errors && (
                <small className="text-red-400">
                  {errors.customerId?.message}
                </small>
              )}
            </div>
            {type === "CREATE" && (
              <button
                className="bg-primary py-1 px-4 shadow rounded-md text-white "
                onClick={(e) => {
                  e.preventDefault();
                  onCloseModalFastRegisterCustomer(true);
                }}
              >
                <HiUserAdd fontSize={22} />
              </button>
            )}
          </div>
        )}

        {type === "EDIT" && !watch("isBlock") && (
          <Button
            className="mb-5 -mt-4 primary w-full shadow"
            onClick={handleViewInfosCustomer}
          >
            <div className="flex gap-2 items-center">
              <div>Ver detalhes do cliente</div>
              <PiUserListLight fontSize={20} />
            </div>
          </Button>
        )}

        {userPermission !== "USER" ? (
          <div className="mb-6">
            <Controller
              name="professionalId"
              control={control}
              rules={{ required: "Este campo é obrigatório" }}
              render={({ field }) => (
                <AutoCompleteInput
                  label="Selecione o profisisonal *"
                  options={professionalsAppointment}
                  onChange={(selectedOption) =>
                    field.onChange(selectedOption.value)
                  }
                  placeholder=""
                  isLoading={isLoadingProfessionals}
                  value={field.value}
                />
              )}
            />
            {errors && (
              <small className="text-red-400">
                {errors.professionalId?.message}
              </small>
            )}
          </div>
        ) : (
          <Input
            type="hidden"
            register={register}
            name="professionalId"
            value={user.id}
          />
        )}

        <div className="mb-6 flex gap-6 md:gap-2 flex-col md:flex-row">
          <SelectInput
            label={`Horário Início *`}
            name="startHour"
            required={true}
            register={register}
            error={errors?.startHour}
          >
            {renderHourOptions(15)}
          </SelectInput>
          {!watch("isBlock") ? (
            <div>
              <SelectInput
                label="Tempo *"
                name="endHour"
                required={true}
                register={register}
                error={errors?.endHour}
              >
                {calculateTimeService().map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </SelectInput>
              <div
                className="mt-1 italic text-gray-500"
                style={{ fontSize: "11px", lineHeight: "14px" }}
              >
                O tempo ajusta-se automaticamente com base nos serviços
                adicionados
              </div>
            </div>
          ) : (
            <SelectInput
              label="Horário Fim *"
              name="endHourTime"
              required={true}
              register={register}
              error={errors?.endHourTime}
            >
              {renderHourOptions(15)}
            </SelectInput>
          )}
        </div>

        {!watch(`isBlock`) && (
          <>
            <div className="mt-6">
              <h3 className="mb-3">Serviços</h3>
              {appointmentServices?.map((field, index) => (
                <Card className="mb-3" key={index}>
                  <div className="flex gap-3 flex-col lg:flex-row">
                    <div className="flex gap-2 flex-col md:flex-row w-full">
                      <Controller
                        name={`Services[${index}].AppointmentService.ServiceId`}
                        control={control}
                        render={({ field }) => (
                          <AutoCompleteInput
                            label="Serviço *"
                            options={servicesAppointment}
                            placeholder=""
                            isLoading={isLoadingServices}
                            value={watch(
                              `Services[${index}].AppointmentService.ServiceId`
                            )}
                            onChange={(selectedOption) => {
                              field.onChange(selectedOption.value);

                              setValue(
                                "endHour",
                                watch("Services").reduce(
                                  (accumulator, service) => {
                                    return (
                                      accumulator +
                                      (servicesAppointment.find(
                                        (serviceToFind) =>
                                          serviceToFind.value ===
                                          service.AppointmentService.ServiceId
                                      )?.durationMinutes || 0)
                                    );
                                  },
                                  0
                                )
                              );
                            }}
                          />
                        )}
                      />
                    </div>
                    <div className="flex gap-2">
                      <Input
                        type="number"
                        register={register}
                        label="Quantidade"
                        name={`Services[${index}].AppointmentService.quantity`}
                        value={watch(
                          `Services[${index}].AppointmentService.quantity`
                        )}
                        min="1"
                      />

                      {appointmentServices.length > 1 && (
                        <Button
                          className="bg-secondary flex items-center justify-center"
                          onClick={() => handleDeleteService(index)}
                        >
                          <MdOutlineDelete className="text-lg" />
                        </Button>
                      )}
                    </div>
                  </div>
                </Card>
              ))}
            </div>
            <>
              <div className="flex justify-end mt-3 mb-6 justify-center">
                <Button
                  className="primary shadow flex items-center gap-2"
                  onClick={handleAddService}
                >
                  <span>Adicionar Serviço</span>
                </Button>
              </div>
            </>
          </>
        )}

        {!watch(`isBlock`) && (
          <>
            <div className="mb-6 flex gap-2">
              <Input
                type="hidden"
                name="statusId"
                register={register}
                className="hidden"
              />
              {type === "EDIT" && (
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setValue("statusId", "-1");
                    setValue("cancellationId", 0);
                    setIsQueryEnabledCancellations(true);
                  }}
                  className={`border-gray-200 border py-3 px-2 rounded-md flex flex-col gap-1 w-full items-center shadow-sm
                ${watch("statusId") == "-1" && "bg-red-300"}`}
                >
                  <FaRegCalendarTimes
                    fontSize="20px"
                    className="text-red-800"
                  />
                  <span className="text-sm md:text-md font-semibold text-red-800">
                    Cancelado
                  </span>
                </button>
              )}

              <button
                onClick={(e) => {
                  e.preventDefault();
                  setValue("statusId", "0");
                }}
                className={`border-gray-300 border shadow-sm py-3 px-2 rounded-md flex flex-col gap-1 w-full items-center ${
                  watch("statusId") == "0" && "bg-gray-200"
                }`}
              >
                <FaRegCalendarMinus fontSize="20px" className="text-gray-500" />
                <span className="text-sm md:text-md text-gray-500 font-semibold">
                  Pendente
                </span>
              </button>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setValue("statusId", "1");
                }}
                className={`border-gray-300 border shadow-sm py-3 px-2 rounded-md flex flex-col gap-1 w-full items-center ${
                  watch("statusId") == "1" && "bg-green-200"
                }`}
              >
                <FaRegCalendarCheck
                  fontSize="20px"
                  className="text-green-700"
                />
                <span className="text-sm md:text-md font-semibold text-green-700">
                  Confirmado
                </span>
              </button>
            </div>
          </>
        )}

        {type === "CREATE" && (
          <div className="mb-6">
            <label className="inline-flex items-center me-5 cursor-pointer">
              <input
                type="checkbox"
                className="sr-only peer"
                name="isRepeatable"
                {...register("isRepeatable")}
                checked={watch("isRepeatable")}
              />
              <div className="relative w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-red-300 dark:peer-focus:ring-red-800 dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-red-700"></div>
              <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">
                Este {watch("isBlock") ? "bloqueio" : "agendamento"} se repete?
              </span>
            </label>
          </div>
        )}

        {type === "EDIT" && !watch("isBlock") && watch("statusId") == -1 && (
          <div className="mb-6">
            <SelectInput
              label="Motivo do cancelamento *"
              name="cancellationId"
              register={register}
              value={watch("cancellationId")}
              error={errors.cancellationId?.message}
            >
              <option selected value={0}>
                Selecione um motivo
              </option>
              {!isLoadingCancellations &&
                cancellations?.map((cancellation, index) => (
                  <option key={index} value={cancellation.id}>
                    {cancellation.name}
                  </option>
                ))}
            </SelectInput>
          </div>
        )}

        {watch("isRepeatable") && (
          <div className="mb-6 flex gap-2">
            <SelectInput
              label="Frequência"
              name="repeatableFrequency"
              register={register}
            >
              <option value={1} selected>
                Diariamente
              </option>
              <option value={7}>Semanalmente</option>
              <option value={14}>2 semanas</option>
              <option value={21}>3 semanas</option>
              <option value={30}>Todo mês</option>
            </SelectInput>

            <SelectInput
              label="Repetição"
              name="repeatableTimes"
              register={register}
            >
              <option value={1} selected>
                1 vez
              </option>
              <option value={2}>2 vezes</option>
              <option value={3}>3 vezes</option>
              <option value={4}>4 vezes</option>
              <option value={5}>5 vezes</option>
              <option value={6}>6 vezes</option>
              <option value={7}>7 vezes</option>
              <option value={8}>8 vezes</option>
              <option value={9}>9 vezes</option>
              <option value={10}>10 vezes</option>
            </SelectInput>
          </div>
        )}

        <div className="mb-6">
          <Input
            type="text"
            label="Observações"
            name="observation"
            required={false}
            register={register}
          />
        </div>

        <Button
          type="submit"
          className="w-full primary shadow"
          isProcessing={isSubmitting}
          processingSpinner={
            <AiOutlineLoading className="h-6 w-6 animate-spin" />
          }
          disabled={isSubmitting}
        >
          {type === "CREATE" ? "Criar" : "Atualizar"}{" "}
          {!watch("isBlock") ? "agendamento" : "bloqueio de horário"}
        </Button>

        {!watch("isBlock") && (
          <>
            {type === "CREATE" && (
              <Button
                gradientMonochrome="teal"
                type="submit"
                className="w-full mt-2 shadow"
                isProcessing={isSubmitting}
                processingSpinner={
                  <AiOutlineLoading className="h-6 w-6 animate-spin" />
                }
                disabled={isSubmitting}
                onClick={() => setOpenCommand(true)}
              >
                Criar agendamento e abrir comanda
              </Button>
            )}
          </>
        )}

        {type === "EDIT" && appointment?.statusId === -1 && (
          <Button
            className="mt-2 w-full bg-gray-800 shadow"
            onClick={deleteAppointment}
            isProcessing={isSubmitting}
            processingSpinner={
              <AiOutlineLoading className="h-6 w-6 animate-spin" />
            }
            disabled={isSubmitting}
          >
            Excluir agendamento
          </Button>
        )}

        {type === "EDIT" && watch("isBlock") && (
          <Button
            className="mt-2 w-full bg-gray-500 shadow"
            onClick={deleteAppointment}
            isProcessing={isSubmitting}
            processingSpinner={
              <AiOutlineLoading className="h-6 w-6 animate-spin" />
            }
            disabled={isSubmitting}
          >
            Excluir bloqueio de horário
          </Button>
        )}

        {appointment?.commandId === null &&
          type === "EDIT" &&
          appointment?.statusId !== -1 &&
          !watch("isBlock") && (
            <Button
              type="submit"
              gradientMonochrome="info"
              className="w-full bg-cyan-800 mt-2 shadow"
              isProcessing={isSubmitting}
              processingSpinner={
                <AiOutlineLoading className="h-6 w-6 animate-spin" />
              }
              disabled={isSubmitting}
              onClick={() => setOpenCommand(true)}
            >
              <div className="flex gap-2 items-center">
                <span>Abrir comanda</span>
                <LiaFileInvoiceSolid fontSize={18} />
              </div>
            </Button>
          )}
        {/* 
        {appointment?.commandId === null &&
          type === "EDIT" &&
          appointment?.statusId !== -1 &&
          !watch("isBlock") && (
            <Button
              type="submit"
              className="w-full bg-gray-500 mt-2 shadow"
              isProcessing={isSubmitting}
              processingSpinner={
                <AiOutlineLoading className="h-6 w-6 animate-spin" />
              }
              disabled={isSubmitting}
              onClick={(e) => handleAttributeCommand(e, appointment.id)}
            >
              Atribuir a uma comanda existente
            </Button>
          )} */}

        {appointment?.command !== null &&
          type === "EDIT" &&
          appointment?.command?.statusId === 0 && (
            <Button
              gradientMonochrome="success"
              className="w-full bg-green-500 mt-2 shadow"
              onClick={handleFinishCommand}
            >
              <div className="flex gap-2 items-center">
                <span>Finalizar comanda</span>
                <LiaFileInvoiceDollarSolid fontSize={18} />
              </div>
            </Button>
          )}

        {type === "EDIT" && appointment?.command?.statusId === 1 && (
          <Button
            className="w-full secondary mt-2 shadow"
            onClick={handleFinishCommand}
          >
            Visualizar comanda
          </Button>
        )}

        {submitError && (
          <Alert className="mt-2" color="failure" icon={HiInformationCircle}>
            Ops, ocorreu um erro no envio do agendamento! Tente novamente mais
            tarde.
          </Alert>
        )}
      </form>
      <Modal
        show={openModalFastRegisterCustomer}
        size="md"
        onClose={() => onCloseModalFastRegisterCustomer(false)}
        popup
      >
        <Modal.Header />
        <Modal.Body>
          <div className="space-y-6">
            <h3 className="text-xl font-medium text-gray-900 dark:text-white">
              Cadastro rápido de cliente
            </h3>
            <form onSubmit={handleSubmitCustomer(onSubmitCreateCustomer)}>
              <div className="mb-3">
                <Input
                  type="text"
                  label="Nome *"
                  name="name"
                  required={true}
                  register={registerCustomer}
                  error={errorsCustomer?.name && "Este campo é obrigatório"}
                />
              </div>
              <div className="mb-6">
                <PhoneInput
                  type="text"
                  label="Telefone *"
                  name="phoneNumber"
                  required={true}
                  register={registerCustomer}
                  error={
                    errorsCustomer?.phoneNumber && "Este campo é obrigatório"
                  }
                />
              </div>
              <Button
                type="submit"
                className="mx-auto primary"
                isProcessing={isSubmittingCustomer}
                processingSpinner={
                  <AiOutlineLoading className="h-6 w-6 animate-spin" />
                }
                disabled={isSubmittingCustomer}
              >
                Cadastrar
              </Button>
            </form>
          </div>
        </Modal.Body>
      </Modal>

      <Drawer
        title={`Comanda #${selectedCommand?.commandNumber}`}
        onClose={setIsDrawerOpenCommand}
        isOpen={isDrawerOpenCommand}
        className="w-full md:w-2/5 pb-36"
      >
        <CommandForm
          type="EDIT"
          selectedProfessionalId={appointment?.professionalId}
          command={selectedCommand}
          isLoadingCommand={isLoadingCommand}
          setCommand={null}
          handleInputChange={null}
          fetchCommands={() => {
            queryClient.invalidateQueries(["selectedCommand"]);
            fetchAppointments();
            onClose(false);
          }}
          onClose={setIsDrawerOpenCommand}
          isDrawerOpen={isDrawerOpenCommand}
          paymentTaxes={paymentTaxes}
        />
      </Drawer>

      <Drawer
        title={`${appointment?.Customer?.name}`}
        onClose={() => {
          setIsDrawerInfosOpen(false);
          setShouldFetchCustomerInfos(false);
        }}
        isOpen={isDrawerInfosOpen}
        className="w-full md:w-2/5 pb-36"
      >
        <CustomerInfosForm
          customer={appointment?.Customer}
          onClose={setIsDrawerInfosOpen}
          isDrawerOpen={isDrawerInfosOpen}
          shouldFetchCustomerInfos={shouldFetchCustomerInfos}
        />
      </Drawer>
      {(isLoadingAppointment ||
        isLoadingCustomers ||
        isLoadingProfessionals ||
        isLoadingServices) && <DrawerLoading />}
    </>
  );
};

export default AppointmentForm;
