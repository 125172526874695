import { Card } from "flowbite-react";
import { FaRegCheckCircle } from "react-icons/fa";

const PaymentConfirm = () => {
  return (
    <Card>
      <div
        className="text-center flex items-center justify-center flex-col gap-2"
        style={{ minHeight: "200px" }}
      >
        <FaRegCheckCircle color="green" fontSize={30} />
        <div className="ml-2 text-lg font-semibold">
          Pagamento confirmado com sucesso!
        </div>
        <div className="text-center">
          O sistema será atualizado para o PRO quando recebermos o pagamento,
          isso pode levar até 2 horas.
          <br />
          Obrigado por escolher a <b>Relinq</b>. <br />
          Conte conosco nessa sua nova jornada!
        </div>
        <div className="flex gap-1 text-sm">
          Se tiver algum problema técnico, você pode nos chamar através do nosso{" "}
          <a
            href="https://wa.me/+5515996730404"
            className="underline color-primary"
            target="_blank"
          >
            Whatsapp
          </a>
          .
        </div>
      </div>
    </Card>
  );
};

export default PaymentConfirm;
