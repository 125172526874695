import React, { useState } from "react";
import { Alert, Badge, Button, Card } from "flowbite-react";
import Chart from "react-apexcharts";
import { useQuery } from "react-query";
import { APPOINTMENTS, CUSTOMERS } from "../config/apiConfig";
import axios from "../config/axiosInstance";
import moment from "moment";
import { Icon } from "@mui/material";
import { RiVipFill } from "react-icons/ri";
import {
  formatWhatsappMessage,
  formatBirthdayMessage,
} from "../config/whatsapp-template-msg";
import { MdCelebration } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import { useApplication } from "../contexts/ApplicationContext";
import { HiInformationCircle } from "react-icons/hi";

const Dashboard = () => {
  const navigate = useNavigate();
  const { user } = useApplication();
  const [filterPendingAppointments, setFilterPendingAppointments] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [returnDays, setReturnDays] = useState(7);
  const [birthday, setBirthday] = useState(1);

  const {
    isLoadingListLastAppointments,
    error: errorListLastAppointments,
    data: listLastAppointments,
  } = useQuery(["listLastAppointments"], async () => {
    const { data } = await axios.get(`${APPOINTMENTS.GET_APPOINTMENTS}/total`);

    return data.data.map((item) => ({
      [item.date]: item.totalAppointments,
    }));
  });

  const {
    isLoadingListAppointmentsPending,
    error: errorListAppointmentsPending,
    data: listAppointmentsPending = [],
  } = useQuery(
    ["listAppointmentsPending", filterPendingAppointments],
    async () => {
      const { data } = await axios.get(
        `${APPOINTMENTS.GET_APPOINTMENTS}/pending?date=${filterPendingAppointments}`
      );

      return data.data;
    }
  );

  const {
    isLoadingListAppointmentsTotalServices,
    error: errorListAppointmentsTotalServices,
    data: listAppointmentsTotalServices = [],
  } = useQuery(["listAppointmentsTotalServices"], async () => {
    const { data } = await axios.get(
      `${APPOINTMENTS.GET_APPOINTMENTS}/services/total`
    );

    return data.data;
  });

  const {
    isLoadingReturnCustomers,
    error: errorReturnCustomers,
    data: returnCustomers = [],
  } = useQuery(["returnCustomers", returnDays], async () => {
    const { data } = await axios.get(
      `${APPOINTMENTS.GET_APPOINTMENTS}/return?dateDays=${returnDays}`
    );

    return data.data;
  });

  const {
    isLoadingCustomersBirthdays,
    error: errorCustomersBirthdays,
    data: customersBirthday = [],
  } = useQuery(["customersBirthday", birthday], async () => {
    const { data } = await axios.get(
      `${CUSTOMERS.GET_CUSTOMERS}/birthdays?birthday=${birthday}`
    );

    return data.data;
  });

  const {
    isLoadingAppointmentsWeekAverage,
    error: errorAppointmentsWeekAverage,
    data: appointmentsWeekAverage = [],
  } = useQuery(["appointmentsWeekAverage"], async () => {
    const { data } = await axios.get(
      `${APPOINTMENTS.GET_APPOINTMENTS}/week/average`
    );

    return Object.values(data.data).map((item) => ({ x: "Domingo", y: item }));
  });

  const chartColors = [
    "var(--secondary)",
    "#00E396",
    "#FEB019",
    "#FF4560",
    "#775DD0",
  ];

  return (
    <>
      <div className="rounded-lg dark:border-gray-700 mt-3">
        {user?.Company?.planId === "FREE" && (
          <a onClick={() => navigate("/payment")} className="cursor-pointer">
            <video
              autoPlay
              muted
              loop
              playsInline
              poster="/assets/banner_pro.jpg"
              className="w-full rounded-md mb-5 h-16 md:h-auto object-cover"
            >
              <source src="/assets/banner_pro.mp4" type="video/mp4" />
            </video>
          </a>
        )}
        {user?.Company?.planId === "PRO" &&
          moment(user?.Company?.planExpirationDate, "YYYY-MM-DD").isBefore(
            moment().subtract(4, "days")
          ) && (
            <Alert
              withBorderAccent
              color="failure"
              className="mb-5"
              icon={HiInformationCircle}
            >
              Não conseguimos identificar o pagamento do seu plano PRO. Caso o
              pagamento não seja confirmado em breve, seu plano será revertido
              para a versão FREE.{" "}
              <Link to="/payment" className="underline">
                Pague agora mesmo
              </Link>
            </Alert>
          )}

        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4 mb-4">
          <Card className="text-center card-dashboard">
            <div className="text-lg font-bold flex flex-col items-center justify-center gap-2">
              <span>Agendamentos pendentes</span>{" "}
              <div className="flex gap-2">
                <Badge
                  color="pink"
                  className={`border cursor-pointer ${
                    filterPendingAppointments !==
                    moment(new Date()).format("YYYY-MM-DD")
                      ? "bg-white"
                      : "border-transparent"
                  }`}
                  size="xs"
                  onClick={() =>
                    setFilterPendingAppointments(
                      moment(new Date()).format("YYYY-MM-DD")
                    )
                  }
                >
                  Hoje
                </Badge>
                <Badge
                  size="xs"
                  className={`border cursor-pointer ${
                    filterPendingAppointments !==
                    moment(new Date()).add(1, "days").format("YYYY-MM-DD")
                      ? "bg-white"
                      : "border-transparent"
                  }`}
                  color="pink"
                  onClick={() =>
                    setFilterPendingAppointments(
                      moment(new Date()).add(1, "days").format("YYYY-MM-DD")
                    )
                  }
                >
                  Amanhã
                </Badge>
              </div>
            </div>
            <div
              className="flex flex-col gap-3 justify-center"
              style={{
                overflowY: "hidden",
                height: "240px",
              }}
            >
              <div
                className="flex flex-col gap-3"
                style={{
                  overflowY: "scroll",
                }}
              >
                {!isLoadingListAppointmentsPending && (
                  <>
                    {listAppointmentsPending.length > 0 ? (
                      <>
                        {listAppointmentsPending.map(
                          (appointmentPending, index) => (
                            <Card
                              key={index}
                              className="bg-gray-100 card-next-appointments"
                            >
                              <div className="flex flex-row justify-between items-center gap-2">
                                <div className="flex flex-col items-start">
                                  <span className="text-sm">
                                    Horário:{" "}
                                    {moment(
                                      appointmentPending.startHour,
                                      "HH:mm:ss"
                                    ).format("HH:mm")}
                                    h
                                  </span>
                                  <span className="text-sm font-bold block w-fit mb-1 text-left">
                                    {appointmentPending?.Customer?.name}
                                  </span>
                                  <span className="text-sm w-fit block mb-1">
                                    {appointmentPending?.Services?.map(
                                      (service) => service.name
                                    ).join(" + ")}
                                  </span>
                                  <Badge className="w-fit block">
                                    {appointmentPending?.Professional?.name}
                                  </Badge>
                                </div>
                                <a
                                  className="flex flex-col items-center bg-primary rounded-lg px-3 py-2 shadow-lg"
                                  href={`https://wa.me/55${appointmentPending.Customer?.phoneNumber.replace(
                                    /[\(\)\s,-]/g,
                                    ""
                                  )}?text=${formatWhatsappMessage(
                                    appointmentPending?.Customer?.name,
                                    appointmentPending?.id
                                  )}`}
                                  target="_blank"
                                >
                                  <Icon className="text-white">phone</Icon>
                                </a>
                              </div>
                            </Card>
                          )
                        )}
                      </>
                    ) : (
                      <h2>Nenhum agendamento pendente para amanhã</h2>
                    )}
                  </>
                )}
              </div>
              {user?.Company?.planId === "FREE" ? (
                <small className="font-normal text-xs italic text-yellow-800">
                  Com o plano PRO, você tem direito a mensagens automáticas para
                  seus clientes!
                  <Link to="/payment" className="underline block">
                    Contratar agora
                  </Link>
                </small>
              ) : (
                <small className="font-normal text-xs italic text-yellow-800">
                  Configure os lembretes automáticos para os seus clientes.
                  <Link to="/reminders" className="underline block">
                    Configurar agora
                  </Link>
                </small>
              )}
            </div>
          </Card>

          <Card className="card-dashboard">
            <h2 className="text-lg text-center font-bold">
              Agendamentos Últimos 7 dias
            </h2>
            {listLastAppointments && !isLoadingListLastAppointments && (
              <Chart
                options={{
                  chart: {
                    id: "basic-bar",
                    toolbar: {
                      show: false,
                    },
                  },
                  xaxis: {
                    categories: listLastAppointments.map((obj) => {
                      const [, month, day] = Object.keys(obj)[0].split("-");
                      return `${day}/${month}`;
                    }),
                  },
                  colors: chartColors,
                  yaxis: {
                    forceNiceScale: true,
                  },
                }}
                series={[
                  {
                    name: "Nº de agendamentos",
                    data: listLastAppointments.map(
                      (obj) => Object.values(obj)[0]
                    ),
                  },
                ]}
                type="bar"
                width="100%"
              />
            )}
          </Card>

          <Card className="card-dashboard">
            <h2 className="text-lg text-center font-bold">
              Ranking de serviços por profissional
              <Badge
                color="pink"
                size="xs"
                className="block w-fit mx-auto mt-2"
              >
                Último mês
              </Badge>
            </h2>
            {!isLoadingListAppointmentsTotalServices && (
              <>
                <div className="mt-4">
                  {listAppointmentsTotalServices.length > 0 ? (
                    <>
                      <div className="flex justify-between gap-2 mb-3 font-semibold uppercase">
                        <span className="text-xs">Profissional</span>
                        <span className="text-xs">Qtd.</span>
                      </div>
                      {listAppointmentsTotalServices.map(
                        (appointment, index) => (
                          <div key={index} className="mb-2">
                            <div className="flex items-center justify-between flex-row">
                              <span className="text-sm">
                                {appointment.Professional?.name}
                              </span>{" "}
                              <span className="font-bold flex gap-2 items-center">
                                {index === 0 && (
                                  <Icon className="color-primary">
                                    emoji_events
                                  </Icon>
                                )}
                                <span>{appointment.totalAppointments}</span>
                              </span>
                            </div>
                          </div>
                        )
                      )}
                    </>
                  ) : (
                    <h2 className="text-center mt-5 mb-5 md:mt-24">
                      Nenhum serviço realizado mês passado.
                    </h2>
                  )}
                </div>
              </>
            )}
          </Card>

          <Card className="card-dashboard" style={{ minHeight: "270px" }}>
            <h2 className="text-center font-bold text-lg">
              {`Clientes atendidos `}
              <div className="flex gap-2 mt-2 justify-center">
                <Badge
                  color="pink"
                  size="xs"
                  className={`border cursor-pointer ${
                    returnDays === 7 ? "border-transparent" : "bg-white"
                  }`}
                  onClick={() => setReturnDays(7)}
                >
                  +7 dias
                </Badge>
                <Badge
                  color="pink"
                  size="xs"
                  className={`border cursor-pointer ${
                    returnDays === 14 ? "border-transparent" : "bg-white"
                  }`}
                  onClick={() => setReturnDays(14)}
                >
                  +14 dias
                </Badge>
                <Badge
                  color="pink"
                  size="xs"
                  className={`border cursor-pointer ${
                    returnDays === 21 ? "border-transparent" : "bg-white"
                  }`}
                  onClick={() => setReturnDays(21)}
                >
                  +21 dias
                </Badge>
              </div>
            </h2>
            {returnCustomers?.length > 0 ? (
              <ul className="flex flex-col gap-2 mt-4">
                <li className="flex justify-between items-center uppercase text-xs font-semibold">
                  <span>Nome</span>
                  <span>Data</span>
                </li>
                {returnCustomers.map((item, index) => (
                  <li
                    key={index}
                    className="flex justify-between items-center text-sm md:text-md gap-8"
                  >
                    <span>{item.customer?.name}</span>
                    <span>{moment(item?.date).format("DD/MM/YYYY")}</span>
                  </li>
                ))}
              </ul>
            ) : (
              <div className="text-center mt-10 md:mt-28">Nenhum cliente.</div>
            )}
          </Card>
          <Card className="text-center card-dashboard">
            <div className="text-lg font-bold flex items-center justify-center gap-2">
              <span>Aniversariantes</span>
              <MdCelebration className="color-primary text-xl" />
            </div>
            <div className="flex gap-2 justify-center -mt-2">
              <Badge
                color="pink"
                size="xs"
                className={`border cursor-pointer ${
                  birthday === 1 ? "border-transparent" : "bg-white"
                }`}
                onClick={() => setBirthday(1)}
              >
                Dia
              </Badge>
              <Badge
                color="pink"
                size="xs"
                className={`border cursor-pointer ${
                  birthday === 7 ? "border-transparent" : "bg-white"
                }`}
                onClick={() => setBirthday(7)}
              >
                Semana
              </Badge>
              <Badge
                color="pink"
                size="xs"
                className={`border cursor-pointer ${
                  birthday === 30 ? "border-transparent" : "bg-white"
                }`}
                onClick={() => setBirthday(30)}
              >
                Mês
              </Badge>
            </div>
            <div
              className="flex flex-col gap-3 justify-center"
              style={{
                overflowY: "hidden",
                height: "240px",
              }}
            >
              <div
                className="flex flex-col gap-3"
                style={{
                  overflowY: "scroll",
                }}
              >
                {!isLoadingCustomersBirthdays && (
                  <>
                    {customersBirthday.length > 0 ? (
                      <>
                        {customersBirthday.map((customer, index) => (
                          <Card key={index} className="bg-gray-100">
                            <div className="flex flex-row justify-between items-center">
                              <div className="flex flex-col items-start">
                                <span className="text-sm font-bold flex gap-1 items-center">
                                  <span>{customer?.name}</span>
                                  {customer.isVip && (
                                    <RiVipFill className="text-xl" />
                                  )}
                                </span>
                                <span className="text-sm">
                                  Parabenize esse cliente.
                                </span>
                              </div>
                              <a
                                className="flex flex-col items-center bg-primary rounded-lg px-3 py-2 shadow-lg"
                                href={`https://wa.me/55${customer?.phoneNumber.replace(
                                  /[\(\)\s,-]/g,
                                  ""
                                )}?text=${formatBirthdayMessage(
                                  customer?.name
                                )}`}
                              >
                                <Icon className="text-white">phone</Icon>
                              </a>
                            </div>
                          </Card>
                        ))}
                      </>
                    ) : (
                      <h2>Nenhum aniversariante hoje.</h2>
                    )}
                  </>
                )}
              </div>
            </div>
          </Card>
          <Card className="card-dashboard">
            <h2 className="text-center font-bold text-lg">
              Mapa de calor dos dias da semana
            </h2>
            <Chart
              options={{
                chart: {
                  type: "heatmap",
                  toolbar: {
                    show: false, // Oculta o menu do gráfico
                  },
                },
                xaxis: {
                  type: "category",
                  categories: [
                    "Dom.",
                    "Seg.",
                    "Ter.",
                    "Qua.",
                    "Qui.",
                    "Sex.",
                    "Sab.",
                  ],
                },
                plotOptions: {
                  heatmap: {
                    colorScale: {
                      ranges: [
                        {
                          from: 0,
                          to: 5,
                          color: "#ff859d",
                        },
                        {
                          from: 6,
                          to: 10,
                          color: "#fa5c7c",
                        },
                        {
                          from: 11,
                          to: 50,
                          color: "#fc1744",
                        },
                      ],
                    },
                  },
                },
              }}
              series={[
                {
                  name: "Atend.",
                  data: appointmentsWeekAverage,
                },
              ]}
              type="heatmap"
              height={250}
            />
          </Card>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
