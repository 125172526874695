import Input from "../components/inputs/Input";
import { AiOutlineLoading } from "react-icons/ai";
import { Alert, Button, Card, Select } from "flowbite-react";
import { useForm } from "react-hook-form";
import { COMPANIES } from "../config/apiConfig";
import axios from "../config/axiosInstance";
import { useEffect, useState } from "react";
import { useApplication } from "../contexts/ApplicationContext";
import { useQuery } from "react-query";
import { FaWhatsapp } from "react-icons/fa";
import ModalConfirmAdditionalPlan from "../components/modals/ModalConfirmAdditionalPlan";
import SelectInput from "../components/inputs/SelectInput";

const ReminderPage = () => {
  const { user } = useApplication();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorSubmit, setErrorSubmit] = useState(null);
  const [alert, setAlert] = useState({ show: false, message: "", type: "" });

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm();

  const {
    isLoading,
    error,
    data: company,
  } = useQuery(["company", user], async () => {
    if (user?.id) {
      const { data: item } = await axios.get(
        `${COMPANIES.GET_COMPANY}/${user.companyId}`
      );

      return item.data;
    }
  });

  useEffect(() => {
    if (company) {
      Object.keys(company).forEach((key) => {
        setValue(key, company[key]);
      });
    }
  }, [company]);

  const onSubmit = async (data) => {
    try {
      setIsSubmitting(true);

      await axios.put(`${COMPANIES.UPDATE_COMPANY}/${company?.id}`, data);
      setAlert({
        show: true,
        message:
          "Informações de lembretes automáticos atualizadas com sucesso!",
        type: "success",
      });
    } catch (error) {
      setErrorSubmit(error);
      setAlert({
        show: true,
        message:
          "Erro ao tentar atualizar informações de lembretes automáticos !",
        type: "failure",
      });
    } finally {
      setIsSubmitting(false);
      setTimeout(() => {
        setAlert({
          show: false,
          message: "",
          type: "",
        });
      }, 2000);
    }
  };

  return (
    <>
      <Card>
        {!isLoading && (
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-6">
              <label className="inline-flex items-center me-5 cursor-pointer">
                <input
                  type="checkbox"
                  className="sr-only peer"
                  name="isActiveAutomaticReminder"
                  checked={watch("isActiveAutomaticReminder")}
                  onChange={() => {
                    setValue(
                      "isActiveAutomaticReminder",
                      !watch("isActiveAutomaticReminder")
                    );
                  }}
                />
                <div className="relative w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-green-300 dark:peer-focus:ring-green-800 dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-green-700"></div>
                <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">
                  Ativar lembretes automáticos no Whatsapp
                </span>
              </label>
              <div className="text-sm">
                Ativando os lembretes automáticos, todos os agendamentos
                pendentes para o dia seguinte receberão lembretes de confirmação
                de agendamento via WhatsApp.
              </div>
              <div className="mt-5">
                <SelectInput
                  label={`Horário para envio das mensagens *`}
                  name="hourAutomaticReminder"
                  required={true}
                  register={register}
                  error={errors?.startHour}
                >
                  {Array.from({ length: 23 }).map((_, index) => (
                    <option value={index + 1}>{index + 1}h</option>
                  ))}
                </SelectInput>
              </div>
            </div>
            <Button
              type="submit"
              className="w-full primary mt-6"
              isProcessing={isSubmitting}
              processingSpinner={
                <AiOutlineLoading className="h-6 w-6 animate-spin" />
              }
              disabled={isSubmitting}
            >
              Atualizar informações
            </Button>
            {alert.show && (
              <Alert className="mt-4" color={`${alert.type}`}>
                <span className="font-medium">{alert.message}</span>
              </Alert>
            )}
          </form>
        )}
      </Card>
      <ModalConfirmAdditionalPlan />
    </>
  );
};

export default ReminderPage;
