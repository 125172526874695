import Input from "../components/inputs/Input";
import { AiOutlineLoading } from "react-icons/ai";
import { MdOutlineEditNote } from "react-icons/md";
import {
  Accordion,
  Alert,
  Badge,
  Button,
  Card,
  Rating,
  TextInput,
} from "flowbite-react";
import { useForm } from "react-hook-form";
import { CATEGORIES, COMPANIES, PROFESSIONALS } from "../config/apiConfig";
import axios from "../config/axiosInstance";
import { useEffect, useState } from "react";
import { useApplication } from "../contexts/ApplicationContext";
import { useQuery, useQueryClient } from "react-query";
import PhoneInput from "../components/inputs/PhoneInput";
import TextAreaInput from "../components/inputs/TextAreaInput";
import ModalConfirmAdditionalPlan from "../components/modals/ModalConfirmAdditionalPlan";
import { IoPersonAdd, IoPersonRemove } from "react-icons/io5";
import ProfessionalServicesForm from "../components/forms/ProfessionalServicesForm";
import Drawer from "../components/Drawer";
import {
  FaExternalLinkAlt,
  FaFacebookF,
  FaInstagram,
  FaTiktok,
  FaWhatsapp,
} from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";

const SiteOnlinePage = () => {
  const queryClient = useQueryClient();
  const { user } = useApplication();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorSubmit, setErrorSubmit] = useState(null);
  const [alert, setAlert] = useState({ show: false, message: "", type: "" });
  const [isDrawerOpenServices, setIsDrawerOpenServices] = useState(false);
  const [selectedProfessional, setSelectedProfessional] = useState(null);
  const [listProfessionals, setListProfessionals] = useState([]);
  const [listServices, setListServices] = useState([]);

  const {
    register: registerSite,
    getValues: getValuesSite,
    handleSubmit: handleSubmitSite,
    reset: resetSite,
    setValue: setValueSite,
    watch: watchSite,
    formState: { errors: errorsSite },
  } = useForm();

  const {
    isLoading,
    error,
    data: company,
  } = useQuery(["company", user], async () => {
    if (user?.id) {
      const { data: item } = await axios.get(
        `${COMPANIES.GET_COMPANY}/${user.companyId}`
      );

      return item.data;
    }
  });

  const {
    isLoadingSite,
    errorSite,
    data: companySite,
  } = useQuery(["companySite", user], async () => {
    if (user?.id) {
      const { data: item } = await axios.get(
        `${COMPANIES.GET_COMPANY}/site/${user.companyId}`
      );

      return item.data;
    }
  });

  const {
    isLoadingProfessionals,
    errorProfessionals,
    data: professionals,
  } = useQuery(
    ["professionals"],
    async () => {
      const { data: items } = await axios.get(
        `${PROFESSIONALS.GET_PROFESSIONALS}?itemsPerPage=-1&isActive=1`
      );

      return items.data.data;
    },
    { enabled: watchSite("isOnlineCalendar") || false }
  );

  useEffect(() => {
    if (companySite) {
      Object.keys(companySite).forEach((key) => {
        setValueSite(key, companySite[key]);
      });
    }
  }, [companySite]);

  useEffect(() => {
    if (professionals) {
      setListProfessionals(professionals);
    }
  }, [professionals]);

  const {
    isLoadingCategories,
    errorCategories,
    data: categories,
  } = useQuery(["categories"], async () => {
    const { data: items } = await axios.get(
      `${CATEGORIES.GET_CATEGORIES}?itemsPerPage=${-1}`
    );

    let list = [];

    setListServices(
      items.data.data.map((categories) => categories.Services).flat()
    );

    return items.data.data;
  });

  const onSubmitWebsite = async (data) => {
    try {
      setIsSubmitting(true);

      if (data.isOnlineCalendar) {
        data.professionals = listProfessionals;
        data.services = listServices;
      }

      await axios.put(`${COMPANIES.GET_COMPANY}/site`, data);
      setAlert({
        show: true,
        message: "Informações do site atualizadas com sucesso!",
        type: "success",
      });
    } catch (error) {
      setErrorSubmit(error);
      setAlert({
        show: true,
        message: "Erro ao tentar atualizar informações do site!",
        type: "failure",
      });
    } finally {
      setIsSubmitting(false);
      setTimeout(() => {
        setAlert({
          show: false,
          message: "",
          type: "",
        });
      }, 2000);
    }
  };

  const addProfessional = (professional) => {
    setListProfessionals((prev) =>
      prev.map((prevProfessional) => {
        if (prevProfessional.id === professional.id) {
          return { ...prevProfessional, isVisibleOnline: true };
        }
        return prevProfessional;
      })
    );
  };

  const removeProfessional = (professional) => {
    setListProfessionals((prev) =>
      prev.map((prevProfessional) => {
        if (prevProfessional.id === professional.id) {
          return { ...prevProfessional, isVisibleOnline: false };
        }
        return prevProfessional;
      })
    );
  };

  const handleEditServices = (professional) => {
    setSelectedProfessional(professional);
    setIsDrawerOpenServices(true);
  };

  const handleServicesActions = (id, type) => {
    console.log(type);
    setListServices((prevServices) =>
      prevServices.map((service) =>
        service.id === id ? { ...service, [type]: !service[type] } : service
      )
    );
  };

  return (
    <>
      <Card>
        {!isLoadingSite && (
          <form onSubmit={handleSubmitSite(onSubmitWebsite)}>
            <div className="mb-6">
              <label className="inline-flex items-center me-5 cursor-pointer">
                <input
                  type="checkbox"
                  className="sr-only peer"
                  name="isVisibleWebsite"
                  checked={watchSite("isVisibleWebsite")}
                  onChange={() => {
                    setValueSite(
                      "isVisibleWebsite",
                      !watchSite("isVisibleWebsite")
                    );
                  }}
                />
                <div className="relative w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-green-300 dark:peer-focus:ring-green-800 dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-green-700"></div>
                <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">
                  Mostrar website para o público?
                </span>
              </label>
            </div>
            <>
              <div className="mb-8 w-full">
                <TextInput
                  name="urlSite"
                  {...registerSite("urlSite", { required: true })}
                  error={errorsSite?.urlSite && "Este campo é obrigatório"}
                  addon="https://app.relinq.com.br/site/"
                />
                <small className="mt-2 block">
                  O link do seu site será:{" "}
                  <a
                    href={`https://app.relinq.com.br/site/${watchSite(
                      "urlSite"
                    )}`}
                    target="_blank"
                    className="font-semibold"
                  >
                    https://app.relinq.com.br/site/{watchSite("urlSite")}{" "}
                    <FaExternalLinkAlt className="inline ml-1" />
                  </a>
                </small>
              </div>

              <div className="mb-6">
                <label className="inline-flex items-center me-5 cursor-pointer">
                  <input
                    type="checkbox"
                    className="sr-only peer"
                    name="isOnlineCalendar"
                    checked={watchSite("isOnlineCalendar")}
                    onChange={() => {
                      setValueSite(
                        "isOnlineCalendar",
                        !watchSite("isOnlineCalendar")
                      );
                    }}
                  />
                  <div className="relative w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-green-300 dark:peer-focus:ring-green-800 dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-green-700"></div>
                  <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">
                    Abrir agendamento online?
                  </span>
                </label>
              </div>

              {watchSite("isOnlineCalendar") && (
                <div className="mb-6 -mt-2">
                  <div>Selecione os profissionais para agendamento online:</div>
                  <div className="grid sm:grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-4 mt-2">
                    {listProfessionals?.map((professional, index) => (
                      <Card
                        key={index}
                        className={`bg-gray-100 relative ${
                          professional?.isVisibleOnline
                            ? "border-green-500 border"
                            : ""
                        }`}
                      >
                        {professional?.isVisibleOnline && (
                          <Badge
                            className="absolute top-2 right-2"
                            color="green"
                          >
                            Visível
                          </Badge>
                        )}

                        <div className="flex flex-col gap-2 items-center">
                          <div>{professional.name}</div>
                          <div className="flex gap-2">
                            {professional?.isVisibleOnline ? (
                              <Button
                                className="bg-red-700"
                                onClick={() => removeProfessional(professional)}
                              >
                                <IoPersonRemove fontSize={18} />
                              </Button>
                            ) : (
                              <Button
                                className="bg-green-500"
                                onClick={() => addProfessional(professional)}
                              >
                                <IoPersonAdd fontSize={18} />
                              </Button>
                            )}

                            <Button
                              className="bg-gray-500"
                              onClick={() => handleEditServices(professional)}
                            >
                              <MdOutlineEditNote fontSize={22} />
                            </Button>
                          </div>
                        </div>
                      </Card>
                    ))}
                  </div>
                </div>
              )}

              {watchSite("isOnlineCalendar") && (
                <div className="mt-6 mb-6">
                  <div className="mb-2">
                    Selecione os serviços disponíveis para agendamento online:
                  </div>

                  <Accordion collapseAll className="shadow">
                    {categories?.map((category, idxCat) => (
                      <Accordion.Panel key={idxCat}>
                        <Accordion.Title>
                          {category.name} ({category?.Services?.length})
                        </Accordion.Title>
                        <Accordion.Content>
                          <div>
                            {category?.Services?.length > 0 ? (
                              <div className="grid sm:grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-4">
                                {category.Services.map((service, index) => (
                                  <Card key={index} className="relative">
                                    <div>
                                      <label className="flex flex-col gap-2 items-center cursor-pointer justify-between">
                                        <span className="text-sm font-medium text-gray-900 dark:text-gray-300 font-semibold">
                                          {service.name}
                                        </span>
                                        <div className="flex flex-col gap-2 items-center mt-1">
                                          <span className="text-xs">
                                            Visível na agenda online
                                          </span>
                                          <input
                                            type="checkbox"
                                            className="sr-only peer"
                                            name={`services[${index}]`}
                                            checked={
                                              listServices.find(
                                                (lservice) =>
                                                  lservice.id === service.id
                                              )?.isVisibleOnline
                                            }
                                            onChange={() =>
                                              handleServicesActions(
                                                service.id,
                                                "isVisibleOnline"
                                              )
                                            }
                                          />
                                          <div className="relative w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-green-300 dark:peer-focus:ring-green-800 dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-green-700"></div>
                                        </div>
                                      </label>
                                    </div>
                                    <Rating
                                      className="absolute top-1 right-2 z-10"
                                      size="md"
                                    >
                                      <Rating.Star
                                        onClick={() =>
                                          handleServicesActions(
                                            service.id,
                                            "isFavorite"
                                          )
                                        }
                                        filled={
                                          listServices.find(
                                            (lservice) =>
                                              lservice.id === service.id
                                          )?.isFavorite
                                        }
                                      />
                                    </Rating>
                                  </Card>
                                ))}
                              </div>
                            ) : (
                              <div className="text-sm md:text-md text-center">
                                Não há nenhum serviço cadastrado nesta categoria
                              </div>
                            )}
                          </div>
                        </Accordion.Content>
                      </Accordion.Panel>
                    ))}
                  </Accordion>
                </div>
              )}

              <div className="mb-6">
                <TextAreaInput
                  type="text"
                  label="Descrição do site"
                  name="description"
                  required={false}
                  register={registerSite}
                />
              </div>
              <div className="mb-6">
                <Input
                  type="text"
                  label="Endereço"
                  name="address"
                  required={false}
                  register={registerSite}
                />
              </div>
              <div className="grid sm:grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-4 mt-2">
                <Card className="relative">
                  <div className="flex flex-col items-center gap-2">
                    <FaFacebookF
                      className="absolute bottom-0 right-0 text-gray-200"
                      fontSize={80}
                    />
                    <Input
                      type="text"
                      label="Link do Facebook"
                      name="facebook"
                      required={false}
                      register={registerSite}
                    />
                  </div>
                </Card>
                <Card className="relative">
                  <div className="flex flex-col items-center gap-2">
                    <FaInstagram
                      className="absolute bottom-0 right-0 text-gray-200"
                      fontSize={80}
                    />
                    <Input
                      type="text"
                      label="Link do Instagram"
                      name="instagram"
                      required={false}
                      register={registerSite}
                    />
                  </div>
                </Card>
                <Card className="relative">
                  <div className="flex flex-col items-center gap-2">
                    <FaTiktok
                      className="absolute bottom-0 right-0 text-gray-200"
                      fontSize={80}
                    />
                    <Input
                      type="text"
                      label="Link do Tiktok"
                      name="tiktok"
                      required={false}
                      register={registerSite}
                    />
                  </div>
                </Card>
                <Card className="relative">
                  <div className="flex flex-col items-center gap-2">
                    <FaXTwitter
                      className="absolute bottom-0 right-0 text-gray-200"
                      fontSize={80}
                    />
                    <Input
                      type="text"
                      label="Link do Twitter"
                      name="twitter"
                      required={false}
                      register={registerSite}
                    />
                  </div>
                </Card>

                <Card className="relative">
                  <div className="flex flex-col items-center gap-2">
                    <FaWhatsapp
                      className="absolute bottom-0 right-0 text-gray-200"
                      fontSize={80}
                    />
                    <PhoneInput
                      type="text"
                      label="Whatsapp"
                      name="whatsapp"
                      required={false}
                      register={registerSite}
                    />
                  </div>
                </Card>
              </div>
            </>
            <Button
              type="submit"
              className="w-full primary mt-6"
              isProcessing={isSubmitting}
              processingSpinner={
                <AiOutlineLoading className="h-6 w-6 animate-spin" />
              }
              disabled={isSubmitting}
            >
              Atualizar site
            </Button>
            {alert.show && (
              <Alert className="mt-4" color={`${alert.type}`}>
                <span className="font-medium">{alert.message}</span>
              </Alert>
            )}
          </form>
        )}
      </Card>

      <Drawer
        title={`Serviços online`}
        onClose={setIsDrawerOpenServices}
        isOpen={isDrawerOpenServices}
        className={"w-full md:w-4/6 lg:w-2/6"}
      >
        <ProfessionalServicesForm
          professional={selectedProfessional}
          categories={categories}
          fetchProfessionals={() =>
            queryClient.invalidateQueries(["professionals"])
          }
          onClose={setIsDrawerOpenServices}
        />
      </Drawer>
    </>
  );
};

export default SiteOnlinePage;
